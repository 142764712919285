<template>



  <div v-if="backdrop === 1" class="backdrop">&nbsp;</div>


  <div id="app-container" class="wrapper">
    <div class="app-logo">
      <img :src="logoSrc" />
    </div>

    <div class="user-profile-holder">
      <span>&nbsp;</span>
      <span class="user-profile-name">&nbsp;</span>
    </div>

    <div class="dart-target">
      <div class="dart-off-left">
        <svg width="84" height="95">
          <image :xlink:href="dartOffLeftSrc" />
        </svg>
      </div>

      <div class="dart-off-right">
        &nbsp;
      </div>

      <svg width="220" height="208">
        <image :xlink:href="dartTargetSrc" />
      </svg>
    </div>

    <div class="happy-play">
      <svg width="104" height="207">
        <image :xlink:href="happyPlaySrc" />
      </svg>
    </div>


    <div v-if="signinStage === 1" ref="signinContainer">
      <div class="nav-container">
        <div class="nav-option signin-button" @click="setSigninStage(2)">Login/Sign up</div>
      </div>

      <div class="watch-demo-title">
        Watch Demo
      </div>

      <div class="watch-demo-icon">
        <svg width="87" height="61">
          <image :xlink:href="watchDemoSrc" />
        </svg>
      </div>
    </div>

    <transition name="slide-right">
      <div class="signinForm slide" v-if="signinStage === 2" ref="signinForm">
        <div class="mobile-title">Enter Email</div>
        <div class="email-input">
          <span class="country-code">&nbsp;</span> <input inputmode="email" v-model="inputEmail"
            @input="validateEmail" />
        </div>



        <div class="mobile-otp-narration">A one-time <span class="otp-text-1">OTP</span> <span
            class="otp-text-2">code</span> will be sent <br />to your email address
        </div>


        <div class="nav-option signin-button" style="margin-top: -7px;" @click="submitSignin()">Continue</div>

      </div>

    </transition>

    <transition name="slide-right">
      <div class="signinForm slide" v-if="signinStage === 3" ref="signinForm">
        <div class="mobile-title">Enter OTP</div>
        <div class="otp-input">
          <input inputmode="numeric" v-model="inputOtp" @input="validateOtp" />
        </div>
        <div class="mobile-otp-narration">&nbsp;</div>
      </div>
    </transition>

    <transition name="splash">
      <Loading v-if="isLoading == 1" />
    </transition>

    <transition name="fade">
      <Alert v-if="showAlert == 1" @close-alert="showAlert = 0" :alertMessage="alertMessage" />
    </transition>



  </div>
</template>
<script>
import Loading from '@/components/Modals/Loading.vue'
import Alert from '@/components/Modals/Alert.vue'
import axios from 'axios';
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export default {
  components: { Loading, Alert },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      logoSrc: require("@/assets/images/logo.png"),
      dartOffLeftSrc: require("@/assets/svg/dart-off-left.svg"),
      dartOffRightSrc: require("@/assets/svg/dart-off-right.svg"),
      dartTargetSrc: require("@/assets/svg/dart-target.svg"),
      happyPlaySrc: require("@/assets/svg/happy-play.svg"),
      watchDemoSrc: require("@/assets/svg/watch-demo.svg"),
      showSettings: 0,
      backdrop: 0,
      openGeneralModal: 0,
      modalData: [],
      signinStage: 1,
      inputEmail: '',
      inputOtp: '',
      isLoading: 0,
      emailError: true,
      showAlert: 0,
      alertMessage: '',

    };
  },

  methods: {
    openSettings() {
      this.$emit('show-settings');
    },
    openRoute(path) {
      this.$router.push(path);
    },
    setSigninStage(stage) {
      this.signinStage = stage;
    },
    validateEmail() {
      this.emailError = !emailRegex.test(this.inputEmail);
    },

    validateOtp() {
      this.inputOtp = this.inputOtp.replace(/^0+/, '');
      this.inputOtp = this.inputOtp.replace(/\D/g, '');
      if (this.inputOtp.length > 6) {
        this.inputOtp = this.inputOtp.slice(0, 6);
      }
      if (this.inputOtp.length === 6) {
        this.submitOtp();
      }
    },
    async postData(data) {
      try {
        const response = await axios.post(data.endpoint, data.payload);
        this.isLoading = 0;
        return response.data;
      } catch (err) {
        console.error('Error sending POST request:', err);
        let errorMessage = 'An error occurred. Please try again later.';

        if (err.response && err.response.data) {
          if (err.response.data.message) {
            errorMessage = err.response.data.message;
          } else if (err.response.data.error) {
            errorMessage = err.response.data.error;
          }
        } else if (err.message) {
          errorMessage = err.message;
        }
        this.notifications({ type: 'info', message: errorMessage });
      }
      this.isLoading = 0;
    },
    async submitSignin() {
      if (this.emailError) {
        this.notifications({ type: 'info', message: "Please supply a valid email address" });
        return;
      }

      var emailAddress = this.inputEmail;
      this.isLoading = 1;
      var endpoint = this.apiUrl + '/auth/signin';
      console.log('Endpoint: ' + endpoint);
      const data = {
        endpoint: endpoint,
        payload: {
          email: emailAddress,
        }
      };
      try {
        var response = await this.postData(data);
        console.log('Response');
        console.log(response);
        if (response.success) {
          console.log('Stage 3');
          this.setSigninStage(3);
        }

      }
      catch (err) {
        console.log(err);
      }

    },
    async submitOtp() {
      var email = this.inputEmail;
      var otp = this.inputOtp;
      this.isLoading = 1;
      var endpoint = this.apiUrl + '/auth/validate-otp';
      console.log('Endpoint: ' + endpoint);
      const data = {
        endpoint: endpoint,
        payload: {
          email: email,
          otp: otp,
        }
      };
      try {
        var response = await this.postData(data);

        if (typeof (response) != "undefined") {
          if (typeof (response.success) != "undefined") {
            if (response.token) {
              var authToken = response.token;
              localStorage.setItem('auth_token', authToken);
            }
            if (response.data) {
              var authToken = response.token;
              localStorage.setItem('user_data', JSON.stringify(response.data));
            }
            if (response.type && response.type == "signup") {
              this.openRoute('/CompleteSignup')
            }
            else if (response.type && response.type == "signin") {
              this.openRoute('/Start')
            }
            //get session data, and save
            //redirect to start page
            //   console.log('Stage 3');
            // this.setSigninStage(3);
          } else if (typeof (response.message) != "undefined") {
            console.log(response.message);
            this.notifications({ type: 'info', message: response.message });
          }

        }


      }
      catch (err) {
        console.log(err);
        let errorMessage = 'An error occurred. Please try again later.';

        if (err.response && err.response.data) {
          if (err.response.data.message) {
            errorMessage = err.response.data.message;
          } else if (err.response.data.error) {
            errorMessage = err.response.data.error;
          }
        } else if (err.message) {
          errorMessage = err.message;
        }
        this.notifications({ type: 'info', message: errorMessage });
      }

    },
    notifications(data) {
      console.log('New notifcation received', data)
      if (data.type == 'info') {
        this.showAlert = 1;
        this.alertMessage = data.message;
      }
      else {
        this.showToast(data.message);
      }
    },


  }

};
</script>
<style scoped>
body,
html {
  background: #000;
}

.backdrop {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  backdrop-filter: blur(5px);
  z-index: 900;
}

.app-logo {
  text-align: center;
  margin-top: 70px;
}

.user-profile-holder {
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
  text-align: right;
  padding-right: 22%;
}

.dart-target {
  text-align: center;
  margin-top: 70px;
}

.dart-off-left {
  display: inline-block;
  margin-left: -55px;
  margin-top: 65px;
  position: absolute;
}

.dart-off-right {
  display: inline-block;
  margin-left: 195px;
  margin-top: -50px;
  position: absolute;
}

.happy-play {
  text-align: right;
}

.happy-play svg {
  margin-right: -10px;
  margin-top: -65px;
}

.live-game-status {
  margin-top: -120px;
}

.nav-container {
  margin-top: -96px;
  text-align: center;
}

.signinForm {
  margin-top: -125px;
}

.nav-option {
  text-decoration: none;
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
  padding: 10px;
  width: 97px;
  display: block;
  border-radius: 50px;
  background: #570065;
  cursor: pointer;
  margin: 0 auto;
  margin-bottom: 12px;
}

.nav-settings {
  text-align: center;
}

.signin-button {
  width: 237px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.watch-demo-title {
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
  text-transform: capitalize;
  margin-top: 25px;
}

.watch-demo-icon {
  text-align: center;
  margin-top: 5px;
  height: 66px;

}

.mobile-title {
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
}

.email-input {
  text-align: center;
  margin-top: 10px;
}

.email-input input {
  border-radius: 50px;
  border: 3px solid #FFF;
  background: #DADADA;
  width: 237px;
  height: 62px;

  color: #000;
  text-align: center;
  font-family: Angkor;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
  text-align: left;
  text-indent: 8px;
  padding-left: 10px;
  padding-right: 10px;
}

.mobile-input {
  text-align: center;
  margin-top: 10px;
}

.mobile-input input {
  border-radius: 50px;
  border: 3px solid #FFF;
  background: #DADADA;
  width: 237px;
  height: 62px;

  color: #000;
  text-align: center;
  font-family: Angkor;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
  text-align: left;
  text-indent: 58px;
}

.otp-input {
  text-align: center;
  margin-top: 10px;
}

.otp-input input {
  border-radius: 50px;
  border: 3px solid #FFF;
  background: #DADADA;
  width: 116px;
  height: 62px;

  color: #000;
  text-align: center;
  font-family: Angkor;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
  text-align: left;
  text-indent: 20px;
}

.mobile-otp-narration {
  color: #FFF;
  text-align: center;
  font-family: Anek Tamil;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 98.8%;
  /* 11.856px */
  letter-spacing: -0.333px;
  margin-top: 15px;
  margin-bottom: 35px;
}

.otp-text-1 {
  color: #F00;
  font-family: Anek Tamil;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 98.8%;
  letter-spacing: -0.333px;
}

.otp-text-2 {
  color: #F00;
  font-family: Anek Tamil;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 98.8%;
  letter-spacing: -0.333px;
}

.country-code {
  margin-left: 14px;
  position: absolute;
  margin-top: 16px;
  color: #000;
  text-align: center;
  font-family: Angkor;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.3s ease;
}

.slide-right-enter-from {
  transform: translateX(100%);
}

.slide-right-enter-to,
.slide-right-leave-to {
  transform: translateX(0);
}
</style>