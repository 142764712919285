<template>
  <div id="app-container" class="wrapper">
    <div class="app-logo">
      <img :src="logoSrc" />
    </div>

    <div class="user-profile-holder">
      <span>Hi </span>
      <span class="user-profile-name">{{ userName }}</span>
    </div>

    <div class="dart-target">

      <div class="global-home" @click="openRoute('/Start')">
        <svg width="77" height="77">
          <image :xlink:href="globalHomeSrc" />
        </svg>
      </div>

    </div>





    <transition name="splash">
      <Loading v-if="isLoading == 1" />
    </transition>

    <transition name="fade">
      <Alert v-if="showAlert == 1" @close-alert="showAlert = 0" :alertMessage="alertMessage" />
    </transition>

  </div>

  <section>
    <div class="wallet-container">
      <div class="wallet-balance-header">
        My Balance
      </div>
      <div class="wallet-balance-value">
        <span class="wallet-ballance-currency">&#8358;</span>
        <span class="wallet-balance">{{ walletBalance }}</span>
      </div>


    </div>

    <div class="wallet-ctas">
      <div style="margin-top: 60px;">
        <button class="fund-wallet-btn"
          @click="addFunds = true; selectedAmount = null, topupAmount = '', backdrop = 1;">FUND</button>
      </div>
      <div style="margin-top: 30px;">
        <button class="withdraw-wallet-btn" @click="iniWithdrawfund">WITHDRAW</button>
      </div>
    </div>

    <div class="account-information-container" :class="{ 'is-visible': isVisible }">
      <div class="account-header">Send the amount to the below account details.</div>
      <div class="account-title">Account Name:</div>
      <div class="account-value"><strong>OPERA DIGITAL AND INNOVATION LIMITED</strong></div>

      <div class="account-title"><strong>Bank: Parralex Bank</strong></div>
      <div class="account-value">Accoun Number:1000210081</div>

      <div class="account-title"><strong>Narration: Username</strong></div>
      <div class="account-value">{{ userName }}</div>

      <div style="text-align:center;">
        <button class="complete-payment" @click="complete_payment">CLICK HERE AFTER PAYMENT HAS BEEN DONE</button>
      </div>

    </div>

  </section>

  <section>
    <div v-if="addFunds === true" class="add-funds-modal">
      <div class="funds-modal-header">FUND</div>
      <div class="funds-modal-description">
        Select an amount <br />and proceed
      </div>
      <div class="fund-amount-selectors">
        <div v-for="amount in topupAmounts" :key="amount" class="amount-selector" :data-amount="amount"
          @click="selectAmount(amount)" :class="{ 'selected-amount': selectedAmount === amount }">&#8358;{{
        amount.toLocaleString() }}</div>

      </div>
      <div class="fund-amount-container">
        <input class="topup-amount" placeholder="Enter Amount" inputmode="numeric" ref="topupAmount"
          v-model="topupAmount" @input="formatInput($event, 'topupAmount')" />
      </div>

      <div class="fund-amount-proceed" @click="checkoutTopup">
        Proceed
      </div>
      <div @click="addFunds = false; backdrop = 0;" class="close-funds-modal">Close</div>
    </div>
    <div v-if="withdrawFunds === true" class="add-funds-modal">
      <div class="withdraw-modal-header">WITHDRAWAL</div>
      <div class="funds-modal-description">
        Select an amount <br />and proceed
      </div>
      <div class="fund-amount-selectors">
        <div v-for="amount in topupAmounts" :key="amount" class="amount-selector" :data-amount="amount"
          @click="selectAmount(amount)" :class="{ 'selected-amount': selectedAmount === amount }">&#8358;{{
        amount.toLocaleString() }}</div>

      </div>
      <div class="fund-amount-container">
        <input class="topup-amount" placeholder="Enter Amount" inputmode="numeric" ref="topupAmount"
          v-model="topupAmount" @input="formatInput($event, 'topupAmount')" />
      </div>

      <div class="withdraw-amount-proceed" @click="checkoutWithdrawal">
        Proceed
      </div>
      <div class="close-funds-modal" @click="withdrawFunds = false; backdrop = 0;">Close</div>
    </div>
  </section>

  <section>

    <v-layout>

      <v-navigation-drawer v-model="drawer" location="bottom" temporary>

        <div class="beneficiaries">
          <div v-if="!addNewBeneficiary">
            <select v-model="selectedBeneficiary" class="dd_beneficiaries" @change="beneficiarySelected = true;">
              <option style="display: none;">Select Beneficiary</option>

              <option v-for="(option, index) in beneficiaries" :key="index" :value="option.id">
                {{ option.name + ', ' + option.bank.name }}
              </option>

            </select>
            <div v-if="!beneficiarySelected">
              <div class="or-separator"></div>
              <div class="or-text">OR</div>
              <div class="or-separator"></div>
            </div>


            <div v-if="beneficiarySelected" style="margin-top: 20px;margin-bottom: 20px;">
              <button class="complete-withrawal" @click="checkout_withdraw">Continue</button>
            </div>

            <div v-if="!beneficiarySelected" style="margin-top: 20px;margin-bottom: 20px;">
              <button class="newBeneficiaryCTA" @click="newBeneficiaryCTA">Add new beneficiary</button>
            </div>

          </div>








          <div v-if="addNewBeneficiary">
            <select v-model="selectedBank" @change="checkAccountNumber" class="dd_banks">
              <option style="display: none;">Select Bank</option>

              <option v-for="(option, index) in banks" :key="index" :value="option.code">
                {{ option.name }}
              </option>

            </select>

            <div class="beneficiary-account-container">
              <input class="beneficiary-account" placeholder="Enter Account Number" ref="beneficiaryAccountNumber"
                inputmode="numeric" v-model="beneficiaryAccountNumber" @input="checkAccountNumber" />
            </div>
            <div class="newBeneficiaryInfo">
              {{ newBeneficiaryInfo }}
            </div>
            <div style="margin-top: 20px;margin-bottom: 20px;">
              <button class="complete-withrawal" @click="save_beneficiary">Save</button>
            </div>

          </div>



        </div>

      </v-navigation-drawer>


    </v-layout>

  </section>

  <div v-if="backdrop === 1" class="backdrop">&nbsp;</div>

</template>
<script>
import Loading from '@/components/Modals/Loading.vue'
import Alert from '@/components/Modals/Alert.vue'
import axios from 'axios';
//import GameOutcomeTypeModal from '../../components/Modals/GameOutcomeTypeModal.vue'
import { useToast } from 'vue-toast-notification';
import PaystackPop from '@paystack/inline-js';

import $ from 'jquery/src/jquery.js';
import 'chosen-js';
import 'chosen-js/chosen.css';

export default {
  components: { Loading, Alert, PaystackPop },
  data() {
    return {

      logoSrc: require("../../assets/images/logo.png"),
      globalHomeSrc: require("../../assets/svg/global-home.svg"),
      dartOffRightSrc: require("../../assets/svg/dart-off-right.svg"),
      userName: this.getUserData('name').split(' ')[0],
      walletBalance: parseFloat(this.getUserData('balance')).toLocaleString(),
      apiUrl: process.env.VUE_APP_API_URL,
      isLoading: 0,
      token: localStorage.getItem('auth_token'),
      showAlert: 0,
      alertMessage: '',
      addFunds: false,
      withdrawFunds: false,
      toast: useToast(),
      drawer: false,
      topupAmounts: [500, 1000, 2000, 5000],
      selectedAmount: null,
      topupAmount: '',
      backdrop: 0,
      paymentReference: '',
      gatewayAmount: 0,
      paystack_public_key: process.env.VUE_APP_PAYSTACK_PUBLIC_KEY,
      showPaystack: false,
      beneficiaryAccountNumber: '',
      email: '',
      metadata: {

      },
      embedOptions: {
        ref: 'paystackEmbed',
        onClose: () => {
          this.showPaystack = false;
          alert('Payment closed');
        },
      },
      selectedBeneficiary: null,
      selectedBank: null,
      beneficiaries: null,
      banks: null,
      beneficiarySelected: false,
      addNewBeneficiary: false,
      newBeneficiaryInfo: null,
      newAccountVerified: false,
      isVisible: false,
    };
  },

  mounted() {
    this.getBalance();

  },
  methods: {
    toggleDrawer() {
      this.isVisible = !this.isVisible;
    },
    complete_payment() {
      this.openRoute('/PaymentNotification/fund-wallet');
    },
    iniWithdrawfund() {
      this.withdrawFunds = true;
      this.selectedAmount = null;
      this.topupAmount = '';
      this.backdrop = 1;
      //reset to default state (pending for navigation drawer)
    },
    newBeneficiaryCTA() {
      this.addNewBeneficiary = true;
    },
    checkAccountNumber() {
      let accountNumber = this.beneficiaryAccountNumber.replace(/\D/g, '');

      if (accountNumber.length > 10) {
        accountNumber = accountNumber.slice(0, 10);
      }

      this.beneficiaryAccountNumber = accountNumber;
      if (accountNumber.length === 10 && this.selectedBank != null) {
        this.validateAccount();
      }
    },
    async checkout_withdraw() {
      //make request to withdraw
      //debit user if enough funds
      //log transaction
      //make request to payment gateway
      //update transaction status
      //background service to monitor transfer status

      var payload =
      {
        amount: this.topupAmount,
        beneficiary: this.selectedBeneficiary,

      }

      this.isLoading = 1;
      var endpoint = this.apiUrl + '/wallet/withdraw-funds';
      const data = {
        endpoint: endpoint,
        payload: payload
      };
      try {
        var response = await this.postData(data);
        if (response.success) {
          this.walletBalance = response.balance;
          localStorage.setItem('user_data', JSON.stringify(response.data));
          this.notifications({ type: 'info', message: response.message });
          this.drawer = !this.drawer;
          this.withdrawFunds = false;
          this.backdrop = 0;

        }

      }
      catch (err) {
        console.log(err);
      }


    },
    async save_beneficiary() {
      if (this.newAccountVerified && this.newBeneficiaryInfo != null) {
        var payload =
        {
          account_name: this.newBeneficiaryInfo,
          account_number: this.beneficiaryAccountNumber,
          bank_code: this.selectedBank,

        }

        this.isLoading = 1;
        var endpoint = this.apiUrl + '/wallet/new-beneficiary';
        const data = {
          endpoint: endpoint,
          payload: payload
        };
        try {
          var response = await this.postData(data);
          if (response.success) {
            this.beneficiaries = response.beneficiaries;
            this.addNewBeneficiary = false;
            this.newBeneficiaryInfo = null;
            this.newAccountVerified = false;
            this.notifications({ type: '', message: response.message });
            //update beneficiary list
            //go back to list of beneficiaries
          }

        }
        catch (err) {
          console.log(err);
        }

      }
      else {
        this.notifications({ type: 'info', message: 'Enter a valid beneficiary account details' });
      }
    },
    async validateAccount() {
      this.newAccountVerified = false;
      var payload =
      {
        account_number: this.beneficiaryAccountNumber,
        bank_code: this.selectedBank,

      }

      this.isLoading = 1;
      var endpoint = this.apiUrl + '/wallet/validate-bank-account';
      const data = {
        endpoint: endpoint,
        payload: payload
      };
      try {
        var response = await this.postData(data);
        if (response.success) {
          this.newAccountVerified = true;
          this.newBeneficiaryInfo = response.data.account_name;
        }

      }
      catch (err) {
        console.log(err);
      }



    },
    async getBalance() {




      var payload =
      {
        user_info: true,
        banks: true,
        beneficiaries: true,
      }

      this.isLoading = 1;
      var endpoint = this.apiUrl + '/user';
      const data = {
        endpoint: endpoint,
        payload: payload
      };
      try {
        var response = await this.postData(data);
        if (response.success) {
          console.log(response.data.balance.toLocaleString());
          this.walletBalance = parseFloat(response.data.balance).toLocaleString();
          this.banks = response.banks;
          this.beneficiaries = response.beneficiaries;
          localStorage.setItem('user_data', JSON.stringify(response.data));
        }

      }
      catch (err) {
        console.log(err);
      }




    },
    selectAmount(amount) {
      this.selectedAmount = amount;
      this.topupAmount = amount.toLocaleString();
    },
    checkoutWithdrawal() {
      if (this.topupAmount != '' && this.topupAmount.length > 0) {
        this.drawer = !this.drawer;
        this.withdrawFunds = false;
        this.backdrop = 0;
      }
      else {
        this.notifications({ type: 'info', message: 'Please select or enter a valid amount to withdraw' });
      }

    },
    getUserData(key = null) {
      var data = localStorage.getItem('user_data');

      if (data) {
        data = JSON.parse(data);
      }

      if (key && data) {
        return data[key];
      }

      return data;
    },
    openRoute(path) {
      this.$router.push(path);
    },

    notifications(data) {
      console.log('New notifcation received', data)
      if (data.type == 'info') {
        this.showAlert = 1;
        this.alertMessage = data.message;
      }
      else {
        this.showToast(data.message);
      }
    },

    formatInput(event, refName) {
      const inputValue = event.target.value;
      console.log('Formatting input for:', refName);
      const formattedValue = inputValue.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      console.log('New value: ' + formattedValue);
      this.$refs[refName].value = formattedValue;
    },
    paymentCallback(response) {
      console.log(response); // Handle successful payment
    },
    paymentClose() {
      console.log('Payment closed');
    },
    async initializePayment(amount) {
      console.log('Key ', this.paystack_public_key);
      const paystack = new PaystackPop();
      paystack.newTransaction({
        key: this.paystack_public_key,
        email: this.email,
        amount,
        currency: "NGN",
        ref: this.paymentReference,
        onClose: () => {
          console.log("Payment closed");
        },
        callback: async (response) => {
          console.log("Payment successful", response);


          var payload =
          {
            reference: this.paymentReference,
          }

          this.isLoading = 1;
          var endpoint = this.apiUrl + '/wallet/confirm_payment';
          const data = {
            endpoint: endpoint,
            payload: payload
          };
          try {
            var response = await this.postData(data);
            console.log('Response');
            console.log(response);
            if (response.success) {
              //new user balance
              this.walletBalance = response.balance;
              this.addFunds = false;
              this.backdrop = 0;
              this.notifications({ type: '', message: 'Your wallet has been credited successfully' });
              localStorage.setItem('user_data', JSON.stringify(response.data));
            }

          }
          catch (err) {
            console.log(err);
          }


        },
      });
    },
    async checkoutTopup() {
      //isLoading = 1
      //connect to BE to generate new payment intent, call gateway API with payment intent
      //BE confirm paymemt and update user balance if successful
      //FE show new user balance and success route
      if (this.topupAmount != '' && this.topupAmount.length > 0) {

      }
      else {
        this.notifications({ type: 'info', message: 'Please select or enter a valid top up amount' });
        return;
      }

      var payload =
      {
        amount: this.topupAmount,
      }

      this.isLoading = 1;
      var endpoint = this.apiUrl + '/wallet/topup';
      const data = {
        endpoint: endpoint,
        payload: payload
      };
      try {
        var response = await this.postData(data);
        console.log('Response');
        console.log(response);
        if (response.success) {
          this.paymentReference = response.reference;
          this.metadata.custom_fields = response.custom_fields;
          this.email = response.email;
          this.gatewayAmount = response.amount;
          this.showPaystack = true;
          this.addFunds = false;
          this.backdrop = 0;
          this.toggleDrawer();

          //  await this.initializePayment(this.gatewayAmount);

        }

      }
      catch (err) {
        console.log(err);
      }


    },
    async postData(data) {
      try {
        const response = await axios.post(data.endpoint, data.payload, {
          headers: {
            Authorization: `Bearer ${this.token}`,
            'Content-Type': 'application/json',
          }
        });
        this.isLoading = 0;
        return response.data;
      } catch (error) {


        if (error.response.data && error.response.data.message) {
          console.log('Err ', error.response.data.message);
          this.showToast(error.response.data.message);
        }

        console.error('Error sending POST request:', error);
      }
      this.isLoading = 0;
    },
    showToast(message) {
      this.toast.open({
        message: message,
        type: "info",
        duration: 3000,
        className: "app-toast",
      });
    }
  }

};
</script>
<style>
.v-navigation-drawer {
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  background: rgba(255, 255, 255, 0.90) !important;
  height: 40vh !important;

}

.v-navigation-drawer__content {
  padding: 10px;
  text-align: center;
}
</style>
<style scoped>
.app-logo {
  text-align: center;
  margin-top: 70px;
}

.dart-target {
  text-align: center;
  margin-top: 10px;
}

.dart-off-left {
  display: inline-block;
  margin-left: -55px;
  margin-top: 65px;
  position: absolute;
}

.live-game-status {
  margin-top: 0;
}

.wallet-container {
  max-width: 337px;
  margin: 0 auto;
  text-align: center;
}

.wallet-ballance-currency {
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.333px;
  font-weight: bold;
}

.wallet-balance-header {
  margin-top: 20px;
  color: #FFF;

  text-align: center;
  font-family: Angkor;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
}

.wallet-balance-value {
  max-width: 222px;
  height: 63px;
  margin: 0 auto;
  margin-top: 15px;
  border-radius: 30px;
  background: #27D267;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.75) inset;
  color: #FFF;

  text-align: center;
  font-family: Angkor;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;

  letter-spacing: -0.333px;
}

.wallet-ctas {
  background: #252A34;
  height: 277px;
  text-align: center;
  display: table;
  width: 100%;
  margin-top: 25px;
}

.fund-wallet-btn {
  border-radius: 30px;
  background: #570065;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.75) inset;
  width: 222px;
  height: 63px;
  margin: 0 auto;
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
  border: transparent;
}

.withdraw-wallet-btn {
  width: 222px;
  height: 63px;
  flex-shrink: 0;
  border-radius: 30px;
  background: #27D267;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.75) inset;
  margin: 0 auto;
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
  border: transparent;
}

.add-funds-modal {
  max-width: 100%;
  width: 317px;
  height: 485px;
  margin: 0 auto;
  border-radius: 30px;
  border: 6px solid #FFF;
  background: rgba(0, 0, 0, 0.70);
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5000;
}

.funds-modal-header {
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 24.2px */
  letter-spacing: -0.333px;
  max-width: 222px;
  height: 63px;
  border-radius: 30px;
  background: #570065;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.75) inset;
  margin: 0 auto;
  line-height: 63px;
  margin-top: 20px;
}

.funds-modal-description {
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 22px */
  letter-spacing: -0.333px;
  margin-top: 10px;
}

.withdraw-modal-header {
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 24.2px */
  letter-spacing: -0.333px;
  max-width: 222px;
  height: 63px;
  border-radius: 30px;
  background: #27D267;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.75) inset;
  margin: 0 auto;
  line-height: 63px;
  margin-top: 20px;
}

.amount-selector {
  display: inline-block;
  width: 120px;
  height: 63px;
  flex-shrink: 0;
  border-radius: 30px;
  border: 5px solid #FFF;
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 63px;
  letter-spacing: -0.333px;
  margin-top: 10px;
  margin-left: 10px;
}

.fund-amount-container {
  max-width: 286px;
  height: 63px;
  flex-shrink: 0;
  background: #DADADA;

  border: 5px solid #570065;



  color: rgba(0, 0, 0, 0.50);
  text-align: center;
  font-family: Angkor;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 67px;
  /* 26.4px */
  letter-spacing: -0.333px;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 20px;
}

.fund-amount-container input {
  width: 95%;
  height: 82%;
  margin-top: 3px;
  border-radius: 20px;
  background: transparent;
  border: transparent;

  color: rgba(0, 0, 0, 0.50);
  text-align: center;
  font-family: Angkor;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 26.4px */
  letter-spacing: -0.333px;
}

.beneficiary-account-container {
  width: 345px;
  max-width: 100%;
  height: 64.983px;
  flex-shrink: 0;
  background: #DADADA;

  border: 5px solid #000;



  color: rgba(0, 0, 0, 0.50);
  text-align: center;
  font-family: Angkor;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 67px;
  /* 26.4px */
  letter-spacing: -0.333px;
  border-radius: 35px;
  margin: 0 auto;
  margin-top: 20px;
}

.beneficiary-account-container input {
  width: 95%;
  height: 82%;
  margin-top: 3px;
  border-radius: 20px;
  background: transparent;
  border: transparent;

  color: rgba(0, 0, 0, 0.50);
  text-align: center;
  font-family: Angkor;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 26.4px */
  letter-spacing: -0.333px;
}


.fund-amount-proceed {
  max-width: 286px;
  height: 63px;
  flex-shrink: 0;
  background: #fff;

  border: 5px solid #570065;



  color: #570065;
  text-align: center;
  font-family: Angkor;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 26.4px */
  letter-spacing: -0.333px;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 5px;
  line-height: 63px;
}

.withdraw-amount-proceed {
  max-width: 286px;
  height: 63px;
  flex-shrink: 0;
  background: #fff;

  border: 5px solid #27D267;



  color: #27D267;
  text-align: center;
  font-family: Angkor;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 26.4px */
  letter-spacing: -0.333px;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 5px;
  line-height: 63px;
}

.selected-amount {
  background: #570065;
  background: linear-gradient(to right, #58006580 0%, #570065 50%, #58006580 100%);
  transition: background-size 1s ease-in-out;
}

.close-funds-modal {
  color: #F00;
  text-align: center;
  font-family: Angkor;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
  margin-top: 25px;
  text-align: right;
}

.dd_beneficiaries,
.dd_banks {
  color: #FFF;

  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;

  border-radius: 15px;
  background: #252A34;
  box-shadow: 0px 4px 20px -2px rgba(50, 50, 71, 0.04), 0px 0px 1px 0px rgba(12, 26, 75, 0.03);

  width: 100%;
  max-width: 327px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  margin: 0 unset;
  padding: 23px;
}

.complete-withrawal {
  border-radius: 50px;
  background: #570065;
  max-width: 337px;
  width: 100%;
  height: 62px;
  flex-shrink: 0;

  color: #FFF;
  text-align: center;
  text-shadow: 2px 4px 1px rgba(0, 0, 0, 0.50);
  font-family: Angkor;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
}

.newBeneficiaryCTA {
  border-radius: 50px;
  background: #570065;
  max-width: 337px;
  width: 100%;
  height: 62px;
  flex-shrink: 0;

  color: #FFF;
  text-align: center;
  text-shadow: 2px 4px 1px rgba(0, 0, 0, 0.50);
  font-family: Angkor;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
}

.or-separator {
  display: inline-block;
  border-bottom: thin groove #570065;
  width: 40%;
}

.or-text {
  display: inline-block;
  width: 4%;
  margin-left: 8px;
  margin-right: 17px;
  vertical-align: text-top;
}

.newBeneficiaryInfo {
  width: 200px;
  max-width: 100%;
  margin: 0 auto;
  height: 40px;
  overflow: hidden;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  background: rgba(255, 255, 255, 0.90);

  color: #F00;

  font-family: DM Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  font-weight: bold;
}

.account-information-container {
  width: 100%;
  background: #000;
  color: #000;
  position: fixed;
  bottom: -430px;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  min-height: 430px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  transition: bottom 0.5s ease;
}

.account-information-container.is-visible {
  bottom: 0;
}

.account-header,
.account-title,
.account-value {
  padding-left: 22px;
  padding-right: 22px;
  text-align: center;

}

.account-header {
  margin-top: 55px;
  color: #fff;
  font-family: DM Sans;
  font-size: 20px;
  font-style: italic;
  font-weight: 700;
  line-height: 22px;
  text-align: center;

}

.account-title {
  margin-top: 20px;
  color: #FF0000;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  font-weight: bold;

}

.account-value {
  color: #fff;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  font-weight: bold;
}

.complete-payment {
  margin-top: 30px;
  color: #FF0000;
  font-family: Angkor;
  font-size: 15px;
  font-weight: 400;
  line-height: 16.5px;
  letter-spacing: -0.3333333432674408px;
  text-align: center;
  background: #DADADA;

  width: 290px;
  height: 64.98px;
  border-radius: 25px;
}
</style>