import { createApp } from "vue";
import App from "./App.vue";
import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
  createMemoryHistory,
} from "vue-router";
import Signin from "@/components/Signin.vue";
import Play from "@/components/Dashboard/Play.vue";
import Wallet from "@/components/Dashboard/Wallet.vue";
import Profile from "@/components/Dashboard/Profile.vue";
import Start from "@/components/Dashboard/Start.vue";
import GameSubmitted from "@/components/Dashboard/GameSubmitted.vue";
import gameResultPreview from "@/components/Dashboard/GameResultPreview.vue";
import PaymentNotification from "@/components/Dashboard/PaymentNotification.vue";
import CompleteSignup from "@/components/CompleteSignup.vue";
import { checkIfUserIsAuthenticated } from "./auth";
import "jquery/src/jquery.js";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import VueNextSelect from "vue-next-select";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

// Mobile Device Detection Function
async function checkDevice() {
  const fp = await FingerprintJS.load();
  const result = await fp.get();

  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const isMobile =
    ("ontouchstart" in window || navigator.maxTouchPoints > 0) &&
    /iPhone|Android/i.test(userAgent);

  // Check for FingerprintJS mobile detection (if required)
  const isMobileFingerprint = result.visitorId && isMobile;

  if (!isMobile || !isMobileFingerprint) {
    document.cookie = "isMobile=false; path=/; max-age=31536000";
    window.location.href = "https://operaplayandwin.ng/mobile-only";
    return false;
  }
  return true;
}
function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return null;
}

checkDevice().then((isMobile) => {
  if (!isMobile) return;

  const cookieValue = getCookie("isMobile");

  // If it's a non-mobile device from the cookie, exit the app
  if (cookieValue === "false") {
    window.location.href = "https://operaplayandwin.ng/mobile-only";
    return;
  }

  const router = createRouter({
    // history: createWebHashHistory(),
    history: createMemoryHistory(),
    routes: [
      { path: "/", component: Signin },
      {
        path: "/CompleteSignup",
        component: CompleteSignup,
        meta: { requiresAuth: true },
      },
      { path: "/Start", component: Start, meta: { requiresAuth: true } },
      { path: "/play", component: Play, meta: { requiresAuth: true } },
      { path: "/Wallet", component: Wallet, meta: { requiresAuth: true } },
      { path: "/Profile", component: Profile, meta: { requiresAuth: true } },
      {
        path: "/GameSubmitted",
        component: GameSubmitted,
        meta: { requiresAuth: true },
      },
      {
        path: "/PaymentNotification/:type",
        component: PaymentNotification,
        meta: { requiresAuth: true },
      },
      {
        path: "/gameResultPreview/:id",
        component: gameResultPreview,
        name: "gameResultPreview",
        meta: { requiresAuth: true },
      },
    ],
  });

  router.beforeEach((to, from, next) => {
    const isAuthenticated = checkIfUserIsAuthenticated();
    console.log(isAuthenticated);
    if (to.meta.requiresAuth && !isAuthenticated) {
      next("/");
    } else if (to.path === "/" && isAuthenticated) {
      next("/Start");
    } else {
      next();
    }
  });

  const app = createApp(App);
  app.config.productionTip = false;

  app.use(VueToast);
  app.use(router);
  const vuetify = createVuetify({
    components,
    directives,
  });
  app.use(vuetify);
  app.mount("#app");
});
