<template>


<transition name="fade">
<Settings v-if="showSettings === 1" @close-settings="closeSettings" @guide-howtoplay="guideHowtoplay"  @guide-howtoearn="guideHowtoearn" @guide-howtofund="guideHowtofund"/>
</transition>

<transition name="fade">
<GeneralModal v-if="openGeneralModal === 1" @close-dynamicModal="closeDynamicModal"  :modalData="modalData" />
</transition>

<div v-if="backdrop === 1" class="backdrop">&nbsp;</div>


    <div id="app-container" class="wrapper">
      <div class="app-logo">
        <img :src="logoSrc" />
      </div>
  
      <div class="user-profile-holder">
        <span>Hi </span>
        <span class="user-profile-name">{{ userName }}</span>
      </div>
  
      <div class="dart-target">
        <div class="dart-off-left">
          <svg width="84" height="95">
            <image :xlink:href="dartOffLeftSrc" />
          </svg>
        </div>
  
        <div class="dart-off-right">
          <svg width="100" height="100">
            <image :xlink:href="dartOffRightSrc" />
          </svg>
        </div>
  
        <svg width="220" height="208">
          <image :xlink:href="dartTargetSrc" />
        </svg>
      </div>
  
      <div class="happy-play">
        <svg width="104" height="207" >
          <image :xlink:href="happyPlaySrc" />
        </svg>
      </div>
  
      <div class="live-game-status">
        <div class="live-game-label">Live Game</div>
        <div class="live-game-value">00:00</div>
      </div>
  
      <div class="nav-container">
        <router-link to="/play" class="nav-option">Play !</router-link>
        <router-link to="/Wallet" class="nav-option">Wallet</router-link>
        <router-link to="/profile" class="nav-option">Profile</router-link>
      </div>
  
      <div class="nav-settings" @click="openSettings">
        <svg width="40" height="40">
          <image :xlink:href="navSettingsSrc" />
        </svg>
      </div>
    </div>
  </template>
  <script>
  //import Start from '../../components/Dashboard/Start.vue'
  import Settings from '../../components/Modals/Settings.vue'
import GeneralModal from '../../components/Modals/GeneralModal.vue'
  export default {
    components: { Settings, GeneralModal},
    data() {
      return {
        logoSrc: require("../../assets/images/logo.png"),
        dartOffLeftSrc: require("../../assets/svg/dart-off-left.svg"),
        dartOffRightSrc: require("../../assets/svg/dart-off-right.svg"),
        dartTargetSrc: require("../../assets/svg/dart-target.svg"),
        happyPlaySrc: require("../../assets/svg/happy-play.svg"),
        navSettingsSrc: require("../../assets/svg/nav-settings.svg"),
        userName: this.getUserData('name').split(' ')[0],
      showSettings : 0,
      backdrop : 0,
      openGeneralModal:0,
      modalData:[],
      };
    },
   
  methods: {
getUserData(key =  null)
{
  var data = localStorage.getItem('user_data');

if (data) {
  data = JSON.parse(data);
}

if (key && data) {
  return data[key];
}

return data;
},
    openSettings() {
      this.$emit('show-settings');
    },
    openSettings() {
      this.showSettings = 1;
      this.backdrop = 1;
    },
    closeSettings()
    {
      this.showSettings = 0;
      this.backdrop = 0;
    },
    closeDynamicModal()
    {
      this.openGeneralModal = 0;
      this.showSettings = 1;
    },
    guideHowtoplay()
    {
      console.log('cool');
      this.showSettings = 0;
      this.openGeneralModal = 1;
      this.modalData = [
        {
        title:'Settings',
        header:'How to play',
        description:'Select a game',
        narration:'There are 3 element to choose from. Car, Candy and Ball \n Select your preferred element from the options',
        image:'how-to-play.svg',
      }
      ,{
        title:'Settings',
        header:'How to play',
        description:'Select a game type',
        narration:'Select either probability or direct',
        image:'how-to-play.svg',
      },{
        title:'Settings',
        header:'How to play',
        description:'Select 6 item',
        narration:'Select 6 items from the element choosen',
        image:'how-to-play.svg',
      },
      {
        title:'Settings',
        header:'How to play',
        description:'Stake your game',
        narration:'Add your preferred amount and stake your game',
        image:'how-to-play.svg',
      },
      
    ];
    },
    guideHowtoearn()
    {
      this.showSettings = 0;
      this.openGeneralModal = 1;
      this.modalData = [
        {
        title:'Settings',
        header:'How to earn',
        description:'Start up Bonus',
        narration:'For signing up, you will win a sum of N100 in your wallet',
        image:'how-to-earn.svg',
      }
      ,{
        title:'Settings',
        header:'How to earn',
        description:'Daily Bonus',
        narration:'You win N5 after your first play for the day',
        image:'how-to-earn.svg',
      },
      {
        title:'Settings',
        header:'How to play',
        description:'Referral Bonus',
        narration:'When you refer a friend, you win N10',
        image:'how-to-earn.svg',
      },
      {
        title:'Settings',
        header:'How to earn',
        description:'Direct',
        narration:'The amount you staked will be multiplied by the odd on the game',
        image:'how-to-earn.svg',
      },
   
      {
        title:'Settings',
        header:'How to earn',
        description:'Probability',
        narration:'The amount you staked will be multiplied by the odd on the game',
        image:'how-to-earn.svg',
      },
      
    ];
    },
    guideHowtofund()
    {
      this.showSettings = 0;
      this.openGeneralModal = 1;
      this.modalData = [
        {
        title:'Settings',
        header:'How to fund/withdraw',
        description:'Fund',
        narration:'Click on “fund” from the wallet page, select your preferred amount, then click on “proceed”',
        image:'how-to-fund.svg',
      }
      ,{
        title:'Settings',
        header:'How to fund/withdraw',
        description:'Withdraw',
        narration:'Click on “withdraw” from the wallet page, select your preferred amount, then click on “proceed”',
        image:'how-to-fund.svg',
      }];
    },
  }
   
  };
  </script>
<style scoped>
body,html
{
   background: #000;
}
.backdrop
{
  position: absolute;
    height: 100vh;
    width: 100%;
    top: 0;
    backdrop-filter: blur(5px);
    z-index: 900;
}
.app-logo
{
text-align: center;
margin-top: 70px;
}
.user-profile-holder
{
color: #FFF;
text-align: center;
font-family: Angkor;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
text-align: right;
padding-right: 22%;
}
.dart-target
{
   text-align: center;
   margin-top: 70px;
}
.dart-off-left
{
display: inline-block;
margin-left: -55px;
margin-top: 65px;
position: absolute;
}
.dart-off-right
{
display: inline-block;
margin-left: 195px;
margin-top: -50px;
position: absolute;
}
.happy-play
{
   text-align: right;
}
.happy-play svg
{
   margin-right: -10px;
   margin-top: -65px;
}
.live-game-status
{
   margin-top: -120px;
}
.live-game-label
{
   color: #FFF;
text-align: center;
font-family: Chewy;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
}
.live-game-value
{
   color: #F00;
text-align: center;
font-family: Angkor;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
}
.nav-container
{
   margin-top: 20px;
   text-align: center;
}
.nav-option
{
  text-decoration: none;
   color: #FFF;
text-align: center;
font-family: Angkor;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
padding: 10px;
width: 97px;
display: block;
border-radius: 50px;
background: #570065;
cursor: pointer;
margin: 0 auto;
margin-bottom: 12px;
}
.nav-settings
{
   text-align: center;
}
</style>