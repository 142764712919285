<template>
  <div id="app-container" class="wrapper">
    <div class="app-logo">
      <img :src="logoSrc" />
    </div>

    <div class="user-profile-holder">
      <span>Hi </span>
      <span class="user-profile-name">{{ userName }}</span>
    </div>

    <div class="dart-target">

      <div class="global-home" @click="openRoute('/Start')">
        <svg width="77" height="77">
          <image :xlink:href="globalHomeSrc" />
        </svg>
      </div>

    </div>





    <transition name="splash">
      <Loading v-if="isLoading == 1" />
    </transition>

    <transition name="fade">
      <Alert v-if="showAlert == 1" @close-alert="showAlert = 0" :alertMessage="alertMessage" />
    </transition>

  </div>

  <section>
    <div class="wallet-container">
      <div class="wallet-balance-header">
        My Balance
      </div>
      <div class="wallet-balance-value">
        <span class="wallet-ballance-currency">&#8358;</span>
        <span class="wallet-balance">{{ walletBalance }}</span>
      </div>


    </div>

    <div class="wallet-ctas" v-if="paymentType == 'fund-wallet'">
      <div class="notification-fund-wallet">
        Your wallet will be funded within (5mins) as soon as your payed amount is confirmed
      </div>
      <div v-if="paymentType == 'fund-account'" class="notification-fund-account">
        The selected beneficiary account will be credited shortly
      </div>
    </div>

    <div style="text-align: center;">
      <button class="back-to-wallet-btn" @click="openRoute('/Wallet')">Wallet</button>
    </div>
  </section>





  <div v-if="backdrop === 1" class="backdrop">&nbsp;</div>

</template>
<script>
import Loading from '@/components/Modals/Loading.vue'
import Alert from '@/components/Modals/Alert.vue'
import axios from 'axios';
//import GameOutcomeTypeModal from '../../components/Modals/GameOutcomeTypeModal.vue'
import { useToast } from 'vue-toast-notification';
import PaystackPop from '@paystack/inline-js';

import $ from 'jquery/src/jquery.js';
import 'chosen-js';
import 'chosen-js/chosen.css';

export default {
  components: { Loading, Alert, PaystackPop },
  data() {
    return {
      paymentType: this.$route.params.type,
      logoSrc: require("../../assets/images/logo.png"),
      globalHomeSrc: require("../../assets/svg/global-home.svg"),
      dartOffRightSrc: require("../../assets/svg/dart-off-right.svg"),
      userName: this.getUserData('name').split(' ')[0],
      walletBalance: parseFloat(this.getUserData('balance')).toLocaleString(),
      apiUrl: process.env.VUE_APP_API_URL,
      isLoading: 0,
      token: localStorage.getItem('auth_token'),
      showAlert: 0,
      alertMessage: '',
      addFunds: false,
      withdrawFunds: false,
      toast: useToast(),
      drawer: false,
      topupAmounts: [500, 1000, 2000, 5000],
      selectedAmount: null,
      topupAmount: '',
      backdrop: 0,
      paymentReference: '',
      gatewayAmount: 0,
      paystack_public_key: process.env.VUE_APP_PAYSTACK_PUBLIC_KEY,
      showPaystack: false,
      beneficiaryAccountNumber: '',
      email: '',
      metadata: {

      },
      embedOptions: {
        ref: 'paystackEmbed',
        onClose: () => {
          this.showPaystack = false;
          alert('Payment closed');
        },
      },
      selectedBeneficiary: null,
      selectedBank: null,
      beneficiaries: null,
      banks: null,
      beneficiarySelected: false,
      addNewBeneficiary: false,
      newBeneficiaryInfo: null,
      newAccountVerified: false,

    };
  },

  mounted() {
    this.getBalance();

  },
  methods: {


    async getBalance() {




      var payload =
      {
        user_info: true,
        banks: true,
        beneficiaries: true,
      }

      this.isLoading = 1;
      var endpoint = this.apiUrl + '/user';
      const data = {
        endpoint: endpoint,
        payload: payload
      };
      try {
        var response = await this.postData(data);
        if (response.success) {
          console.log(response.data.balance.toLocaleString());
          this.walletBalance = parseFloat(response.data.balance).toLocaleString();
          this.banks = response.banks;
          this.beneficiaries = response.beneficiaries;
          localStorage.setItem('user_data', JSON.stringify(response.data));
        }

      }
      catch (err) {
        console.log(err);
      }




    },

    getUserData(key = null) {
      var data = localStorage.getItem('user_data');

      if (data) {
        data = JSON.parse(data);
      }

      if (key && data) {
        return data[key];
      }

      return data;
    },
    openRoute(path) {
      this.$router.push(path);
    },

    notifications(data) {
      console.log('New notifcation received', data)
      if (data.type == 'info') {
        this.showAlert = 1;
        this.alertMessage = data.message;
      }
      else {
        this.showToast(data.message);
      }
    },



    async postData(data) {
      try {
        const response = await axios.post(data.endpoint, data.payload, {
          headers: {
            Authorization: `Bearer ${this.token}`,
            'Content-Type': 'application/json',
          }
        });
        this.isLoading = 0;
        return response.data;
      } catch (error) {


        if (error.response.data && error.response.data.message) {
          console.log('Err ', error.response.data.message);
          this.showToast(error.response.data.message);
        }

        console.error('Error sending POST request:', error);
      }
      this.isLoading = 0;
    },
    showToast(message) {
      this.toast.open({
        message: message,
        type: "info",
        duration: 3000,
        className: "app-toast",
      });
    }
  }

};
</script>
<style>
.v-navigation-drawer {
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;
  background: rgba(255, 255, 255, 0.90) !important;
  height: 40vh !important;

}

.v-navigation-drawer__content {
  padding: 10px;
  text-align: center;
}
</style>
<style scoped>
.app-logo {
  text-align: center;
  margin-top: 70px;
}

.dart-target {
  text-align: center;
  margin-top: 10px;
}

.dart-off-left {
  display: inline-block;
  margin-left: -55px;
  margin-top: 65px;
  position: absolute;
}

.live-game-status {
  margin-top: 0;
}

.wallet-container {
  visibility: hidden;
  max-width: 337px;
  margin: 0 auto;
  text-align: center;
}

.wallet-ballance-currency {
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.333px;
  font-weight: bold;
}

.wallet-balance-header {

  margin-top: 20px;
  color: #FFF;

  text-align: center;
  font-family: Angkor;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
}

.wallet-balance-value {
  max-width: 222px;
  height: 63px;
  margin: 0 auto;
  margin-top: 15px;
  border-radius: 30px;
  background: #27D267;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.75) inset;
  color: #FFF;

  text-align: center;
  font-family: Angkor;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;

  letter-spacing: -0.333px;
}

.wallet-ctas {
  background: #252A34;
  height: 277px;
  text-align: center;
  display: table;
  width: 100%;
  margin-top: 25px;
}

.fund-wallet-btn {
  border-radius: 30px;
  background: #570065;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.75) inset;
  width: 222px;
  height: 63px;
  margin: 0 auto;
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
  border: transparent;
}

.back-to-wallet-btn {
  width: 90%;
  height: 58px;
  top: 760px;
  left: 54px;
  gap: 0px;
  font-family: Angkor;
  font-size: 32px;
  font-weight: 400;
  line-height: 57.81px;
  letter-spacing: -0.3333333432674408px;
  text-align: center;
  background: #FFFFFF;
  border-radius: 30px;
  margin-top: 50px;
}

.add-funds-modal {
  max-width: 100%;
  width: 317px;
  height: 485px;
  margin: 0 auto;
  border-radius: 30px;
  border: 6px solid #FFF;
  background: rgba(0, 0, 0, 0.70);
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5000;
}

.funds-modal-header {
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 24.2px */
  letter-spacing: -0.333px;
  max-width: 222px;
  height: 63px;
  border-radius: 30px;
  background: #570065;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.75) inset;
  margin: 0 auto;
  line-height: 63px;
  margin-top: 20px;
}

.funds-modal-description {
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 22px */
  letter-spacing: -0.333px;
  margin-top: 10px;
}

.withdraw-modal-header {
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 24.2px */
  letter-spacing: -0.333px;
  max-width: 222px;
  height: 63px;
  border-radius: 30px;
  background: #27D267;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.75) inset;
  margin: 0 auto;
  line-height: 63px;
  margin-top: 20px;
}

.amount-selector {
  display: inline-block;
  width: 120px;
  height: 63px;
  flex-shrink: 0;
  border-radius: 30px;
  border: 5px solid #FFF;
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 63px;
  letter-spacing: -0.333px;
  margin-top: 10px;
  margin-left: 10px;
}

.fund-amount-container {
  max-width: 286px;
  height: 63px;
  flex-shrink: 0;
  background: #DADADA;

  border: 5px solid #570065;



  color: rgba(0, 0, 0, 0.50);
  text-align: center;
  font-family: Angkor;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 67px;
  /* 26.4px */
  letter-spacing: -0.333px;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 20px;
}

.fund-amount-container input {
  width: 95%;
  height: 82%;
  margin-top: 3px;
  border-radius: 20px;
  background: transparent;
  border: transparent;

  color: rgba(0, 0, 0, 0.50);
  text-align: center;
  font-family: Angkor;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 26.4px */
  letter-spacing: -0.333px;
}

.beneficiary-account-container {
  width: 345px;
  max-width: 100%;
  height: 64.983px;
  flex-shrink: 0;
  background: #DADADA;

  border: 5px solid #000;



  color: rgba(0, 0, 0, 0.50);
  text-align: center;
  font-family: Angkor;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 67px;
  /* 26.4px */
  letter-spacing: -0.333px;
  border-radius: 35px;
  margin: 0 auto;
  margin-top: 20px;
}

.beneficiary-account-container input {
  width: 95%;
  height: 82%;
  margin-top: 3px;
  border-radius: 20px;
  background: transparent;
  border: transparent;

  color: rgba(0, 0, 0, 0.50);
  text-align: center;
  font-family: Angkor;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 26.4px */
  letter-spacing: -0.333px;
}


.fund-amount-proceed {
  max-width: 286px;
  height: 63px;
  flex-shrink: 0;
  background: #fff;

  border: 5px solid #570065;



  color: #570065;
  text-align: center;
  font-family: Angkor;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 26.4px */
  letter-spacing: -0.333px;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 5px;
  line-height: 63px;
}

.withdraw-amount-proceed {
  max-width: 286px;
  height: 63px;
  flex-shrink: 0;
  background: #fff;

  border: 5px solid #27D267;



  color: #27D267;
  text-align: center;
  font-family: Angkor;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 26.4px */
  letter-spacing: -0.333px;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 5px;
  line-height: 63px;
}

.selected-amount {
  background: #570065;
  background: linear-gradient(to right, #58006580 0%, #570065 50%, #58006580 100%);
  transition: background-size 1s ease-in-out;
}

.close-funds-modal {
  color: #F00;
  text-align: center;
  font-family: Angkor;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
  margin-top: 25px;
  text-align: right;
}

.dd_beneficiaries,
.dd_banks {
  color: #FFF;

  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;

  border-radius: 15px;
  background: #252A34;
  box-shadow: 0px 4px 20px -2px rgba(50, 50, 71, 0.04), 0px 0px 1px 0px rgba(12, 26, 75, 0.03);

  width: 100%;
  max-width: 327px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  margin: 0 unset;
  padding: 23px;
}

.complete-withrawal {
  border-radius: 50px;
  background: #570065;
  max-width: 337px;
  width: 100%;
  height: 62px;
  flex-shrink: 0;

  color: #FFF;
  text-align: center;
  text-shadow: 2px 4px 1px rgba(0, 0, 0, 0.50);
  font-family: Angkor;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
}

.newBeneficiaryCTA {
  border-radius: 50px;
  background: #570065;
  max-width: 337px;
  width: 100%;
  height: 62px;
  flex-shrink: 0;

  color: #FFF;
  text-align: center;
  text-shadow: 2px 4px 1px rgba(0, 0, 0, 0.50);
  font-family: Angkor;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
}

.or-separator {
  display: inline-block;
  border-bottom: thin groove #570065;
  width: 40%;
}

.or-text {
  display: inline-block;
  width: 4%;
  margin-left: 8px;
  margin-right: 17px;
  vertical-align: text-top;
}

.newBeneficiaryInfo {
  width: 200px;
  max-width: 100%;
  margin: 0 auto;
  height: 40px;
  overflow: hidden;
  border-bottom-right-radius: 50px;
  border-bottom-left-radius: 50px;
  background: rgba(255, 255, 255, 0.90);

  color: #F00;

  font-family: DM Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  font-weight: bold;
}

.notification-fund-wallet,
.notification-fund-account {
  color: #fff;
  font-family: DM Sans;
  font-size: 24px;
  font-style: italic;
  font-weight: 700;
  line-height: 22px;
  text-align: center;
  padding: 25px;
  margin-top: 75px;

}
</style>