<template>
    <div  class="modal">
<div class="modal-off-icon">
    <svg width="64" height="64">
          <image :xlink:href="navSettingsSrc" />
        </svg>
</div>
<div class="modal-off-title">Settings</div>

<div class="nav-container">
        <div class="nav-option" @click="guideHowToPlay">How to play</div>
        <div class="nav-option" @click="guideHowToEarn">How to earn</div>
        <div class="nav-option" @click="guideHowToFund">How to <br/>Fund/Withdraw</div>
</div>
<div class="modal-close" @click="closeSettings">Close</div>

    </div>
</template>
<script>

export default {
 
  data() {
    return {
      
      navSettingsSrc: require("../../assets/svg/nav-settings-large.svg"),
 
    };
  },
  methods: {
        closeSettings() {
      this.$emit('close-settings');
    },
    guideHowToPlay()
    {
        console.log('Emitting');
        this.$emit('guide-howtoplay');
    },
    guideHowToEarn()
    {
        console.log('Emitting');
        this.$emit('guide-howtoearn');
    },
    guideHowToFund()
    {
        console.log('Emitting');
        this.$emit('guide-howtofund');
    },
    
    
  }
  
};
</script>
<style scoped>
.modal
{
    max-width: 312px;
    width: 75%;
    height: auto;
    color: #fff;
    text-align: center;
    line-height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5000;
    border-radius: 30px;
    border: 6px solid #FFF;
    background: rgba(255, 255, 255, 0.50);
}
.nav-container
{
   margin-top: 20px;
   text-align: center;
}
.nav-option
{
    color: #FFF;
    text-align: center;
    font-family: Angkor;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.333px;
    padding: 10px;
    width: 175px;
    display: block;
    border-radius: 50px;
    background: #570065;
    cursor: pointer;
    margin: 0 auto;
    margin-bottom: 12px;
}
.modal-off-icon{
    display: inline-block;
    position: absolute;
    left: 0;
    margin-left: -20px;
    margin-top: -30px;
}
.modal-off-title
{
    display: inline-block;
    position: absolute;
    margin-top: -70px;
    left: 0;
    margin-left: 53px;
    font-family: Angkor;
font-size: 13px;
font-style: normal;
font-weight: 400;
}
.modal-close
{
    display: inline-block;
    position: absolute;
    color: #F00;
text-align: center;
font-family: Angkor;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
right: 0;
    margin-right: 12px;
    margin-top: 12px;
}
</style>