<template>
    <div id="app-container" class="wrapper">
      <div class="app-logo">
        <img :src="logoSrc" />
      </div>
  
      <div class="user-profile-holder">
        <span>Hi </span>
        <span class="user-profile-name">{{ userName }}</span>
      </div>
  
      <div class="dart-target">
       
        <div class="global-home" @click="openRoute('/Start')">
          <svg width="77" height="77">
            <image :xlink:href="globalHomeSrc" />
          </svg>
        </div>

        <div class="live-game-status">
        <div class="live-game-label">Live Game</div>
        <div class="live-game-value">00:00</div>
      </div>

        <div v-if="showPreview" class="game-stage-title">
          Stake your <span class="stage-styled-text">game</span>
        </div>
        <div v-else-if="showGameSelector" class="game-stage-title">
          Select a <span class="stage-styled-text">6 items</span>
        </div>

        <div v-else-if="gameTypeModal" class="game-stage-title">
          Select a <span class="stage-styled-text">game type</span>
        </div>

        <div v-else class="game-stage-title">
          Select a <span class="stage-styled-text">game</span>
        </div>

         <!-- 
    <div v-if="showPreview" class="selected-outcome-label">{{ selectedOutcomeLabel }}</div>
    -->

       
  
      </div>
  
      <section ref="game-type-group">
        
      <div v-if="startGameMode === 1" class="game-selector-container">
<div class="game-options" :key="0" @click="addOrRemoveClass(1)" :class="{ active_game_option: activeGameOption  && activeGameOption != 1 }">
  <svg width="71" height="60" class="game-1">
          <image :xlink:href="gameOptions1" />
  </svg>
</div>
<div class="game-options mlr15" :key="1" @click="addOrRemoveClass(2)" :class="{ active_game_option: activeGameOption  && activeGameOption != 2 }">
  <svg width="61" height="60">
          <image :xlink:href="gameOptions2" />
        </svg>
</div>
<div class="game-options" :key="2" @click="addOrRemoveClass(3)" :class="{ active_game_option: activeGameOption  && activeGameOption != 3 }">
  <svg width="71" height="60" class="game-3">
          <image :xlink:href="gameOptions3" />
        </svg>
</div>
      </div>
    
   
      <div v-if="activeGameOption !=null && startGameMode === 1" class="start-game-btn" @click="startGameModal" >
        Start Game!
      </div>


    </section>
  
  
  <section ref="outcome-type-group">
<div v-if="gameTypeModal === 1" class="game-outcome-type">


<div class="nav-container">
      <div v-if="gameOutcomeOdds !== null" class="posibilities-option" @click="startSelection(1)"><div  class="posibilities-outcome-title">PROBABILITY</div> <div class="posibilities-outcome-odds">{{ gameOutcomeOdds[0] }}</div></div>
      <div v-if="gameOutcomeOdds !== null" class="posibilities-option" @click="startSelection(2)"><div class="posibilities-outcome-title">DIRECT</div> <div class="posibilities-outcome-odds">{{ gameOutcomeOdds[1] }}</div></div>

</div>


  </div>
</section>


<section ref="game-selection-group">

<div v-if="showGameSelector === 1"  class="game-selector-container">

<div v-for="(item, index) in gameCarts" :key="index" :class="{ 'entry-separator': index % 2 === 0, 'selected-entry': selectedIndices.includes(index) }" @click="updateSelection(index)" class="game-entry">

<svg width="71" height="60" class="entry-svg">
        <image :xlink:href="item" />
</svg>

</div>

</div>


 <div ref="previewSelectionCta" v-show="showGameSelector === 1" class="continue-game-btn"  :class="{ 'selecting': selectionCompleted !== true }" @click="previewSelection" >
      Continue
  </div>
</section>

<section ref="game-preview-group">

    <div v-if="showPreview" class="selected-outcome-label">{{ selectedOutcomeLabel }}</div>



<div v-if="showPreview" class="selected-games-container">
  
  <div v-for="(item, index) in this.computeSelectedGames()" :key="index" :class="{ 'entry-separator': index % 2 === 0 }"  class="selected-preview-entry">

<svg width="60" height="60" class="entry-svg">
        <image :xlink:href="item" />
</svg>

</div>

<div class="stake-game-label">I am staking</div>

<div class="stake-amount-container">
<span class="stake-currency">&#8358;</span><span class="stake-value"><input v-model="stakeInputAmount" placeholder="0" inputmode="numeric" ref="stakeInputAmount" @input="formatInput($event, 'stakeInputAmount')" maxlength="7" /></span>
</div>

<div class="stake-game-label">To win</div>


<div class="stake-amount-container win-amount-container">
<span class="stake-currency">&#8358;</span><span class="stake-value"><input v-model="stakeOutcomeAmount" placeholder="0" inputmode="numeric" ref="stakeOutcomeAmount" @input="formatInput($event, 'stakeOutcomeAmount')" maxlength="7" /></span>
</div>

<div class="play-game-btn"   @click="playGame">
  Play!
</div>

</div>
</section>

<transition name="splash">
        <Loading v-if="isLoading    ==  1" />
</transition>

<transition name="fade">
        <Alert v-if="showAlert    ==  1" @close-alert="showAlert = 0" :alertMessage="alertMessage"  />
</transition>
    
</div>
 
 

  </template>
  <script>
   import Loading from '@/components/Modals/Loading.vue'
   import Alert from '@/components/Modals/Alert.vue'
   import axios from 'axios';
  //import GameOutcomeTypeModal from '../../components/Modals/GameOutcomeTypeModal.vue'
  import {useToast} from 'vue-toast-notification';

  export default {
    components: {Loading, Alert},
    data() {
      return {
        stakeInputAmount:'',
        stakeOutcomeAmount:'',
        selectedOutcomOdd:'',
        gameOutcomeLabels:['PROBABILITY', 'DIRECT'],
        gameOutcomeOdds:[],
        selectedOutcomeLabel:null,
        startGameMode:1,
        gameTypeModal:0,
        activeGameOption:null,
        logoSrc: require("../../assets/images/logo.png"),
        gameOptions1: require("../../assets/svg/gameOptions1.svg"),
        gameOptions2: require("../../assets/svg/gameOptions2.svg"),
        gameOptions3: require("../../assets/svg/gameOptions3.svg"),
        globalHomeSrc: require("../../assets/svg/global-home.svg"),
        dartOffRightSrc: require("../../assets/svg/dart-off-right.svg"),
     //   dartTargetSrc: require("../../assets/svg/dart-target.svg"),
        happyPlaySrc: require("../../assets/svg/happy-play.svg"),
        navSettingsSrc: require("../../assets/svg/nav-settings.svg"),
        userName: this.getUserData('name').split(' ')[0],
        gameCarts:null,
        selectedOutcomeType:null,
        showGameSelector: 0,
        selectionCompleted: false,
        finalSelections:[],
        userSelectedGames:[],
        selectedIndices: [],
        showPreview:false,
        gameReference:null,
        apiUrl : process.env.VUE_APP_API_URL,
        isLoading: 0,
        token: localStorage.getItem('auth_token'),
        showAlert:0,
        alertMessage:'',
        toast:useToast(),
      };
    },
    
    watch: {
      stakeInputAmount: function(newVal) {
        newVal  = newVal.replace(/\D/g, ''); 
        newVal  = newVal  * this.selectedOutcomOdd;
        newVal = newVal.toString();
        this.stakeOutcomeAmount = newVal.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
   
    },
    stakeOutcomeAmount: function(newVal) {
        newVal  = newVal.replace(/\D/g, ''); 
        newVal  = newVal  / this.selectedOutcomOdd;
        newVal = newVal.toString();
        this.stakeInputAmount = newVal.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
      selectionCompleted(newValue)
      {
   
  }
  },
  mounted()
  {
    console.log(this.$refs);

  },
  methods: {
    getUserData(key =  null)
{
  var data = localStorage.getItem('user_data');

if (data) {
  data = JSON.parse(data);
}

if (key && data) {
  return data[key];
}

return data;
},
    openRoute(path) {
      this.$router.push(path);
    },
        openSettings() {
      this.$emit('show-settings');
    },
    addOrRemoveClass(index) {
      this.activeGameOption = index;
    },
    startGameModal()
    {
      if(this.activeGameOption  !=  null)
      {
        this.gameOutcomeOdds = [];
        var liveGameData = localStorage.getItem('live_data');
        if(liveGameData !=  null)
        {
          liveGameData  = JSON.parse(liveGameData);
        }
if(liveGameData  &&  liveGameData.odds)
{
  this.gameOutcomeOdds[0]  = liveGameData.odds.proberbility;
  this.gameOutcomeOdds[1]  = liveGameData.odds.direct;
  this.gameTypeModal = 1;
  this.startGameMode = 0;
  this.gameReference = liveGameData.ref;
}
else
{
this.notifications({type:'info', message:'No Game information available'});
}
    
      }
      else
      {
        this.notifications({type:'info', message:'No Game active games could be retrieved'});
      }
    
    },
  notifications(data)
      {
        console.log('New notifcation received', data)
        if(data.type == 'info')
        {
          this.showAlert = 1;
          this.alertMessage = data.message;
        }
        else
        {
          this.showToast(data.message);
        }
      },
    startSelection(type)
    {
      this.gameTypeModal = 0;
      this.showGameSelector = 1;
      this.selectedOutcomeType = type;
      this.gameCarts = this.gameCartLists(this.activeGameOption - 1);
      this.selectedOutcomeLabel = this.gameOutcomeLabels[this.selectedOutcomeType - 1];
      this.selectedOutcomOdd = this.gameOutcomeOdds[this.selectedOutcomeType - 1];
    //  this.startGameMode = 0;


/*
  <svg width="71" height="60" class="game-1">
          <image :xlink:href="gameOptions1" />
  </svg>
*/
    
    },
    formatInput(event, refName) {
      const inputValue = event.target.value;
      console.log('Formatting input for:', refName);
      const formattedValue = inputValue.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      console.log('New value: '+formattedValue);
      this.$refs[refName].value = formattedValue;
    },
    computeSelectedGames()
    {
      var selectedList = this.gameCartLists(this.activeGameOption - 1);
     // console.log('List: ');
      //console.log(selectedList);

     // console.log('Selections: ');
     // console.log(this.finalSelections);


    //  var finalList = selectedList.filter(item => !this.finalSelections.includes(item));
    var finalList = selectedList.filter((value, index) => !this.finalSelections.includes(index));
      //console.log(finalList);
      this.userSelectedGames  = finalList;
      return finalList;

    },
    updateSelection(index)
    {
      if( this.finalSelections.length == 3)
      {
    console.log('Stack full');
    return;
      }
    //  this.finalSelections[finalSelections.length -1] = index;
      if (this.finalSelections.indexOf(index) === -1) {
        console.log('Selection added to list');
  this.finalSelections.push(index);
}

if (this.selectedIndices.includes(index)) {
        this.selectedIndices = this.selectedIndices.filter(i => i !== index);
      } else {
        this.selectedIndices.push(index);
      }
      if( this.finalSelections.length == 3)
      {
  this.selectionCompleted = true;
  console.log('Selection completed');

  setTimeout(() => {
    this.scrollToElement(this.$refs.previewSelectionCta);
      }, 500);

      }
    },
    previewSelection()
    {
      if(this.finalSelections.length != 3)
      {

      this.notifications({type:'info', message:'Please select at least 6 items'});
return;
      }
      this.showGameSelector  = 0;
      this.showPreview  = true;
      this.selectionCompleted = false;
      
    },
    gameCartLists(index)
    {
      const carImages = Array.from({ length: 9 }, (_, i) =>
  require(`../../assets/svg/1/${i + 1}.svg`)
);

const candyImages = Array.from({ length: 9 }, (_, i) =>
  require(`../../assets/svg/2/${i + 1}.svg`)
);

const ballImages = Array.from({ length: 9 }, (_, i) =>
  require(`../../assets/svg/3/${i + 1}.svg`)
);
const lists = [carImages, candyImages, ballImages];
return lists[index];
    },
    scrollToElement(element) {
     
      if (element) {
        console.log('Scrolling');
        element.scrollIntoView({ behavior: "smooth" });
      }
      else
      {
        console.log(element);
        console.log('element to scroll to not found');
      }
      
    },
    async playGame()
    {
      //selectedOutcomeLabel selectedOutcomOdd selectedOutcomeType
      var checkoutGameOutcome = this.selectedOutcomeType; //1 == probability, 2 ==  direct, 
      var checkoutGameOdds = this.selectedOutcomOdd; //1.5 ....
      var checkoutGameOutcomeTitle = this.selectedOutcomeLabel; ////direct, probability
      var checkoutGameSelections = this.userSelectedGames; ////{img/4.9b64a1ab.svg, img/5.eb339289.svg,img/6.2207984f.svg,img/7.8386a7ec.svg, img/8.2e027bad.svg, img/9.21a2e485.svg}
      var checkoutGameOption = this.activeGameOption; ////1=car, 2= candy, 3= ball
      var liveGameData = localStorage.getItem('live_data');
      //post to server
      var payload = 
      {
        stake_amount:this.stakeInputAmount,
        outcome_amount:this.stakeOutcomeAmount,
        game_category:  checkoutGameOption,
        outcome_value:  checkoutGameOutcome,
        outcome_label:  checkoutGameOutcomeTitle,
        outcome_odds:  checkoutGameOdds,
        selections:  checkoutGameSelections,
        selected_indices:  this.selectedIndices,//{0: 0, 1: 1, 2: 2}
        game_ref:  this.gameReference,//{0: 0, 1: 1, 2: 2}
      }
    
      console.log('Payload');
      console.log(payload);

     


      this.isLoading = 1;
        var endpoint = this.apiUrl  +   '/dashboard/play';
        const data = {
    endpoint:endpoint,
    payload:payload
    };
try
{
    var response = await  this.postData(data);
    console.log('Response');
    console.log(response);
    if(response.success)
    {
       //if server responds with success
       localStorage.setItem('user_data', JSON.stringify(response.data));
       this.$router.push('/GameSubmitted');
    }
   
}
catch(err)
{
    console.log(err);
}

      
    },
    async postData(data) {
      try {
        const response = await axios.post(data.endpoint, data.payload, {
            headers: {
              Authorization: `Bearer ${this.token}`,
              'Content-Type': 'application/json',
            }
        });
        this.isLoading = 0;
        return response.data;
      } catch (error) {


        if(error.response.data && error.response.data.message)
  {
    console.log('Err ',error.response.data.message);
    this.showToast(error.response.data.message);
  }

        console.error('Error sending POST request:', error);
      }
      this.isLoading = 0;
    },
    showToast(message) {
      this.toast.open({
        message: message,
        type: "info",
        duration: 3000, 
        className: "app-toast",
      });
    }
  }
   
  };
  </script>
<style scoped>
.play-game-btn
{
  border-radius: 50px;
    background: #570065;

    height: 62px;
    color: #FFF;
    text-align: center;
    text-shadow: 2px 4px 1px rgba(0, 0, 0, 0.50);
    font-family: Angkor;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.333px;
    margin: 0 auto;
    margin-top: 50px;
    line-height: 60px;
}
.stake-amount-container
{
  border-radius: 30px;
background: #570065;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.75) inset;
width: 222px;
height: 63px;
line-height: 63px;
color: #FFF;

text-align: center;
font-family: Angkor;
font-size: 40px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
margin: 0 auto;
}
.win-amount-container
{
  background: #27D267;
}
.stake-amount-container span
{
  display: inline-block;
  margin-top: 7px;
}
.stake-amount-container input
{
  background: none;
  border: none;
  height: 60%;
max-width: 100%;
  color: #FFF;

text-align: center;
font-family: Angkor;
font-size: 40px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
text-align: left;
    text-indent: 10px;
}
.selected-entry svg
{
  visibility: hidden;
}
.selected-outcome-label
{
  color: #FFF;
    text-align: center;
    font-family: Angkor;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.333px;
    margin-top: 8px;
}
.stake-currency
{
  width: 30%;
  text-align: right;
}
.stake-value
{
  width: 70%;
}
.game-selector-container
{
  max-width: 312px;
    width: 75%;
    height: auto;
    color: #fff;
    text-align: center;
    z-index: 5000;
    border-radius: 30px;
    /*border: 6px solid #FFF;*/
    background: #252A34;
    position: relative;
    margin: 0 auto;
    margin-top: 45px;
    
}
.selected-games-container
{
  max-width: 312px;
    width: 75%;
    height: auto;
    color: #fff;
    text-align: center;
    z-index: 5000;
    border-radius: 30px;
    /*border: 6px solid #FFF;*/
   /* background: #252A34;*/
    position: relative;
    margin: 0 auto;
    margin-top: 5px;
    padding-bottom: 33px;
}
.game-entry{
  border-radius: 30px;
    border: 3px solid #FFF;
    background: #000;
    width: 80px;
    height: 85px;
    display: inline-block;
    margin-top: 33px;
}
.selected-preview-entry{
    border-radius: 30px;
    border: 3px solid #FFF;
    background: #000;
    width: 70px;
    height: 70px;
    display: inline-block;
    margin-top: 20px;
}
.selected-preview-entry image
{
  width: 100%;
}
.entry-svg
{
  margin-top: 10px;
}
.entry-separator{
  margin-right: 10px;
    margin-left: 10px;
}
#app-container
{
  position: relative;
    display: inline-block;
    width: 100%;
}
.game-outcome-type
{
  max-width: 312px;
  width: 75%;
  height: auto;
  color: #fff;
  text-align: center;

  z-index: 5000;
  border-radius: 30px;
  border: 6px solid #FFF;
  background: #252A34;
  position: relative;
  margin: 0 auto;
  margin-top: 45px;
}

.active_game_option
{
  filter: blur(5px);
}
.selecting
{
  filter: blur(2px);
}
.game-stage-title
{
  color:#fff;
  border-radius: 30px;
border: 3px solid #FFF;
background: #000;
display: inline-block;
min-width: 140px;
text-align: center;
height: 27px;
    line-height: 27px;
    margin-top: 10px;
}
.stage-styled-text
{
  color: #F00;
font-family: Chewy;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
}
.game-selector-container
{
  border-radius: 30px;
    background: #252A34;
    width: 317px;
    /* height: 155px; */
    margin: 0 auto;
    margin-top: 50px;
    text-align: center;
    padding-bottom: 33px;
}

.game-options
{
  border-radius: 30px;
border: 3px solid #FFF;
background: #000;
width: 80px;
height: 85px;
display: inline-block;
margin-top: 33px;

}
.posibilities-option
{
  border-radius: 30px;
background: #570065;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.75) inset;
width: 222px;
height: 63px;
margin: 0 auto;
margin-top: 30px;
    margin-bottom: 30px;
    display: inline-block;
}
.posibilities-outcome-title{
  padding-top: 12px;
  color: #FFF;
text-align: center;
font-family: Angkor;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
}
.posibilities-outcome-odds{
  color: #27D267;
text-align: center;
font-family: Angkor;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
}
.game-1
{
  margin-right: 5px;
}
.game-3
{
  margin-right: 11px !important;
    margin-top: 15px !important;
}
.game-options svg
{
  margin-top: 13px;
}
.continue-game-btn
{
  border-radius: 50px;
background: #570065;
max-width: 337px;
height: 62px;
color: #FFF;
text-align: center;
text-shadow: 2px 4px 1px rgba(0, 0, 0, 0.50);
font-family: Angkor;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
margin: 0 auto;
margin-top: 50px;
margin-bottom: 50px;
    line-height: 60px;
}
.start-game-btn
{
  border-radius: 50px;
background: #570065;
max-width: 337px;
height: 62px;
color: #FFF;
text-align: center;
text-shadow: 2px 4px 1px rgba(0, 0, 0, 0.50);
font-family: Angkor;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
margin: 0 auto;
margin-top: 50px;
    line-height: 60px;
}
.mlr15
{
  margin-right: 15px;
  margin-left: 15px;
}


.app-logo
{
text-align: center;
margin-top: 70px;
}
.user-profile-holder
{
color: #FFF;
text-align: center;
font-family: Angkor;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
text-align: right;
padding-right: 22%;
}
.dart-target
{
   text-align: center;
   margin-top: 10px;
}
.dart-off-left
{
display: inline-block;
margin-left: -55px;
margin-top: 65px;
position: absolute;
}
.dart-off-right
{
display: inline-block;
margin-left: 195px;
margin-top: -50px;
position: absolute;
}
.happy-play
{
   text-align: right;
}
.happy-play svg
{
   margin-right: -10px;
   margin-top: -65px;
}
.live-game-status
{
margin-top: 0;
}
.live-game-label
{
   color: #FFF;
text-align: center;
font-family: Chewy;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
margin-top: 10px;
}
.live-game-value
{
   color: #F00;
text-align: center;
font-family: Angkor;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
}
.nav-container
{
   margin-top: 20px;
   text-align: center;
}
.nav-option
{
   color: #FFF;
text-align: center;
font-family: Angkor;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
padding: 10px;
width: 97px;
display: block;
border-radius: 50px;
background: #570065;
cursor: pointer;
margin: 0 auto;
margin-bottom: 12px;
}
.nav-settings
{
   text-align: center;
}
.stake-game-label
{
  color: #FFF;
text-align: center;
font-family: Chewy;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
margin-top: 20px;
    margin-bottom: 10px;
}
.stake-value input::placeholder {
            color: #ffffff; 
        }

</style>