<template>
    <div class="loading-container">
      <img src="@/assets/gif/loading.gif" alt="Loading..." />
    </div>
  </template>
  
  <script>
  export default {
    name: "Loading",
  };
  </script>
  
  <style>
  .loading-container {
    text-align: center;
    filter: blur(0px);
    position: fixed;
    z-index: 10000;
    top: 0;
    width: 100%;
    max-width: 100%;
    background: #57006570;
    height: 100vh;
    overflow: hidden;
  }
  .loading-container img{
    width: 100px;
    position: relative;
    bottom: -37%;
  }
  </style>
  