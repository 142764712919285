<template>



  <div v-if="backdrop === 1" class="backdrop">&nbsp;</div>


  <div id="app-container" class="wrapper">
    <div class="app-logo">
      <img :src="logoSrc" />
    </div>

    <div class="user-profile-holder">
      <span>&nbsp;</span>
      <span class="user-profile-name">&nbsp;</span>
    </div>

    <div class="dart-target">
      <div class="dart-off-left">
        <svg width="84" height="95">
          <image :xlink:href="dartOffLeftSrc" />
        </svg>
      </div>

      <div class="dart-off-right">
        &nbsp;
      </div>

      <svg width="220" height="208">
        <image :xlink:href="dartTargetSrc" />
      </svg>
    </div>

    <div class="happy-play">
      <svg width="104" height="207">
        <image :xlink:href="happyPlaySrc" />
      </svg>
    </div>


    <div ref="signinContainer">

      <div class="signinForm slide" ref="signinForm">
        <div class="mobile-title" style="margin-top: 10px;">Enter Phone No.</div>
        <div class="mobile-input">
          <span class="country-code">+234 </span> <input inputmode="numeric" v-model="inputValue"
            @input="validateInput" />
        </div>
        <div class="mobile-title" style="margin-top: 10px;">Create your username</div>
        <div class="name-input">
          <input type="text" v-model="fullName" maxlength="50" @input="removeSpaces('fullName')" />
        </div>

        <br />
        <div class="mobile-title">Enter Referral Code</div>
        <div class="referral-input">
          <input type="text" v-model="referralCode" maxlength="6" />
        </div>

        <div class="mobile-otp-narration">&nbsp;</div>
      </div>

      <div class="nav-container">
        <div class="nav-option signin-button" @click="completeSignup">Get Started!</div>
      </div>


    </div>




    <transition name="splash">
      <Loading v-if="isLoading == 1" />
    </transition>

    <transition name="fade">
      <Alert v-if="showAlert == 1" @close-alert="showAlert = 0" :alertMessage="alertMessage" />
    </transition>

  </div>
</template>
<script>
import Loading from '@/components/Modals/Loading.vue'
import Alert from '@/components/Modals/Alert.vue'
import axios from 'axios';
export default {
  components: { Loading, Alert },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL,
      logoSrc: require("@/assets/images/logo.png"),
      dartOffLeftSrc: require("@/assets/svg/dart-off-left.svg"),
      dartOffRightSrc: require("@/assets/svg/dart-off-right.svg"),
      dartTargetSrc: require("@/assets/svg/dart-target.svg"),
      happyPlaySrc: require("@/assets/svg/happy-play.svg"),
      watchDemoSrc: require("@/assets/svg/watch-demo.svg"),
      showSettings: 0,
      backdrop: 0,
      signinStage: 1,
      inputValue: '',
      inputOtp: '',
      isLoading: 0,
      fullName: '',
      referralCode: '',
      token: localStorage.getItem('auth_token'),
      showAlert: 0,
      alertMessage: '',
    };
  },

  methods: {
    openRoute(path) {
      this.$router.push(path);
    },
    validateInput() {
      this.inputValue = this.inputValue.replace(/^0+/, '');
      this.inputValue = this.inputValue.replace(/\D/g, '');
      if (this.inputValue.length > 10) {
        this.inputValue = this.inputValue.slice(0, 10);
      }
      if (this.inputValue.length === 10) {
        //  this.submitSignin();
      }
    },
    async postData(data) {
      this.isLoading = 1; // Set loading state before making the request
      try {
        const response = await axios.post(data.endpoint, data.payload, {
          headers: {
            Authorization: `Bearer ${this.token}`,
            'Content-Type': 'application/json',
          }
        });
        return response.data;
      } catch (error) {
        const errorMessage = error.response?.data || 'An unexpected error occurred';
        return errorMessage;
      } finally {
        this.isLoading = 0;
      }
    },

    async completeSignup() {
      var fullname = this.fullName;
      if (this.inputValue.length != 10) {
        this.notifications({ type: 'info', message: "Please enter a valid phone number" });
        return;
      }
      else if (this.fullName.length == 0) {
        this.notifications({ type: 'info', message: "Please enter your username" });
        return;
      }

      var referralCode = this.referralCode;
      this.isLoading = 1;
      var endpoint = this.apiUrl + '/auth/complete-signup';
      const data = {
        endpoint: endpoint,
        payload: {
          username: fullname,
          mobile: this.inputValue,
          referral_code: referralCode,
        }
      };
      try {
        var response = await this.postData(data);
        console.log('Response');
        console.log(response);
        this.isLoading = 0;
        if (typeof (response.success) != "undefined") {
          if (response.data) {
            //  var authToken = response.token;
            localStorage.setItem('user_data', JSON.stringify(response.data));
          }
          this.openRoute('/Start')
        }
        else {
          this.notifications({ type: 'info', message: response.message });
        }

      }
      catch (err) {
        let errorMessage = 'An error occurred. Please try again later.';

        if (err.response && err.response.data) {
          if (err.response.data.message) {
            errorMessage = err.response.data.message;
          } else if (err.response.data.error) {
            errorMessage = err.response.data.error;
          }
        } else if (err.message) {
          errorMessage = err.message;
        }
        this.notifications({ type: 'info', message: errorMessage });
      }

    },
    removeSpaces(field) {
      if (field === 'fullName') {
        this.fullName = this.fullName.replace(/\s+/g, '');
      }
    },

    notifications(data) {
      console.log('New notifcation received', data)
      if (data.type == 'info') {
        this.showAlert = 1;
        this.alertMessage = data.message;
      }
      else {
        this.showToast(data.message);
      }
    },


  }

};
</script>
<style scoped>
body,
html {
  background: #000;
}

.backdrop {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  backdrop-filter: blur(5px);
  z-index: 900;
}

.app-logo {
  text-align: center;
  margin-top: 70px;
}


.mobile-input {
  text-align: center;
  margin-top: 10px;
}

.mobile-input input {
  border-radius: 50px;
  border: 3px solid #FFF;
  background: #DADADA;
  width: 237px;
  height: 62px;

  color: #000;
  text-align: center;
  font-family: Angkor;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
  text-align: left;
  text-indent: 58px;
}

.user-profile-holder {
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
  text-align: right;
  padding-right: 22%;
}

.dart-target {
  text-align: center;
  margin-top: 70px;
}

.dart-off-left {
  display: inline-block;
  margin-left: -55px;
  margin-top: 65px;
  position: absolute;
}

.dart-off-right {
  display: inline-block;
  margin-left: 195px;
  margin-top: -50px;
  position: absolute;
}

.happy-play {
  text-align: right;
}

.happy-play svg {
  margin-right: -10px;
  margin-top: -65px;
}

.live-game-status {
  margin-top: -120px;
}

.nav-container {
  margin-top: -30px;
  text-align: center;
}

.signinForm {
  margin-top: -125px;
}

.nav-option {
  text-decoration: none;
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
  padding: 10px;
  width: 97px;
  display: block;
  border-radius: 50px;
  background: #570065;
  cursor: pointer;
  margin: 0 auto;
  margin-bottom: 12px;
}

.nav-settings {
  text-align: center;
}

.signin-button {
  width: 160px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.watch-demo-title {
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
  text-transform: capitalize;
  margin-top: 25px;
}

.watch-demo-icon {
  text-align: center;
  margin-top: 5px;
  height: 66px;

}

.mobile-title {
  color: #FFF;
  text-align: center;
  font-family: Angkor;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
}

.referral-input {
  text-align: center;
  margin-top: 10px;
}

.referral-input input {
  border-radius: 50px;
  border: 3px solid #FFF;
  background: #DADADA;
  width: 116px;
  height: 62px;

  color: #000;
  text-align: center;
  font-family: Angkor;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
  text-align: left;
  text-indent: 34px;
}

.name-input {
  text-align: center;
  margin-top: 10px;
}

.name-input input {
  border-radius: 50px;
  border: 3px solid #FFF;
  background: #DADADA;
  width: 237px;
  height: 62px;

  color: #000;
  text-align: center;
  font-family: Angkor;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.333px;
  text-align: left;
  text-indent: 30px;
}

.mobile-otp-narration {
  color: #FFF;
  text-align: center;
  font-family: Anek Tamil;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 98.8%;
  /* 11.856px */
  letter-spacing: -0.333px;
  margin-top: 15px;
  margin-bottom: 35px;
}

.otp-text-1 {
  color: #F00;
  font-family: Anek Tamil;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 98.8%;
  letter-spacing: -0.333px;
}

.otp-text-2 {
  color: #F00;
  font-family: Anek Tamil;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 98.8%;
  letter-spacing: -0.333px;
}

.country-code {
  margin-left: 14px;
  position: absolute;
  margin-top: 16px;
  color: #000;
  text-align: center;
  font-family: Angkor;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.3s ease;
}

.slide-right-enter-from {
  transform: translateX(100%);
}

.slide-right-enter-to,
.slide-right-leave-to {
  transform: translateX(0);
}
</style>