<template>
    <div id="app-container" class="wrapper">
      <div class="app-logo">
        <img :src="logoSrc" />
      </div>
  
      <div class="user-profile-holder">
        <span>Hi </span>
        <span class="user-profile-name">{{ userName }}</span>
      </div>
  
      <div class="dart-target">
       
        <div class="global-home" @click="openRoute('/Start')">
          <svg width="77" height="77">
            <image :xlink:href="globalHomeSrc" />
          </svg>
        </div>

        <div  v-if="!showResult" >
        <div class="live-game-status">
      &nbsp;
      </div>
      <div class="game-stage-title">
        <strong>Game No.</strong> {{ gameCode }}
      </div>
      <div class="played-game-type">
{{ playedGameType }}
      </div>

    </div>


      <div  v-if="showResult" >
        <div class="live-game-status">
      &nbsp;
      </div>

        <div class="game-status-title">
     Game result
      </div>
      
      </div>

       
  
      </div>
  
   




<section  v-if="!showResult" ref="game-preview-group">

<div  class="selected-games-container">
  
  <div v-for="(item, index) in userSelectedGames" :key="index" :class="{ 'entry-separator': index % 2 === 0 }"  class="selected-preview-entry">

<svg width="60" height="60" class="entry-svg">
  <image :href="require(`../../assets/svg/${userSelectedGameCategory}/${item}.svg`)" />
</svg>

</div>

<div class="stake-game-label">I have staked</div>

<div class="stake-amount-container">
<span class="stake-currency">&#8358;</span><span class="stake-value"><input v-model="stakeInputAmount" disabled /></span>
</div>

<div class="stake-game-label">To win</div>


<div class="stake-amount-container win-amount-container">
<span class="stake-currency">&#8358;</span><span class="stake-value"><input v-model="stakeOutcomeAmount" disabled /></span>
</div>

<div class="play-game-btn"   @click="checkResult">
  Check Result
</div>

</div>
</section>

<section  v-if="showResult">
  <div class="selection-bg">
<div class="result-outcome-sequence">


<div v-for="(item, index) in game_sequence_outcome" :key="index" :class="{ 'entry-separator': index % 2 === 0 }"  class="selected-preview-entry">

<svg width="60" height="60" class="entry-svg">
<image :xlink:href="getImageUrl(item)" />
</svg>

</div>


</div>
</div>
 
<div class="">
<div class="result-section-title">WHAT YOU PLAYED</div>
</div>

<div :class="{ 'user-selection-won':  gameResult == 'won', 'user-selection-lost':  gameResult == 'lost'}">

<div class="selected-games-container">

  <div v-for="(item, index) in userSelectedGames" :key="index" :class="{ 'entry-separator': index % 2 === 0 }"  class="selected-preview-entry">

<svg width="60" height="60" class="entry-svg">
<image :xlink:href="getImageUrl(item)" />
</svg>
</div>

</div>

</div>

<div class="result-section-title">

<div v-if="gameResult == 'won'" >
 <div class='result-text'>YOU WON! </div><div class='result-icon'> <img :src="wonIcon" /></div>
</div>
 <div v-if="gameResult == 'lost'" > 
  <div class='result-text'>YOU LOSE! </div><div class='result-icon'> <img :src="lostIcon" /></div>
</div>

</div>



</section>

<transition name="splash">
        <Loading v-if="isLoading    ==  1" />
</transition>

<transition name="fade">
        <Alert v-if="showAlert    ==  1" @close-alert="showAlert = 0" :alertMessage="alertMessage"  />
</transition>
    
</div>
 
 

  </template>
  <script>
   import Loading from '@/components/Modals/Loading.vue'
   import Alert from '@/components/Modals/Alert.vue'
   import axios from 'axios';
  //import GameOutcomeTypeModal from '../../components/Modals/GameOutcomeTypeModal.vue'
  import {useToast} from 'vue-toast-notification';

  export default {
    components: {Loading, Alert},
    data() {
      return {
        showResult:false,
        gameResult:null,
        gameResultLabel:null,//YOU WON...
        playedGameId: this.$route.params.id,
        gameCode:'',
        playedGameType:'',
        logoSrc: require("../../assets/images/logo.png"),
        gameOptions1: require("../../assets/svg/gameOptions1.svg"),
        gameOptions2: require("../../assets/svg/gameOptions2.svg"),
        gameOptions3: require("../../assets/svg/gameOptions3.svg"),
        globalHomeSrc: require("../../assets/svg/global-home.svg"),
        dartOffRightSrc: require("../../assets/svg/dart-off-right.svg"),
     //   dartTargetSrc: require("../../assets/svg/dart-target.svg"),
        happyPlaySrc: require("../../assets/svg/happy-play.svg"),
        navSettingsSrc: require("../../assets/svg/nav-settings.svg"),
        wonIcon: require("../../assets/svg/wonIcon.svg"),
        lostIcon: require("../../assets/svg/lostIcon.svg"),
        userName: this.getUserData('name').split(' ')[0],
        gameCarts:null,
 
        stakeInputAmount:0,
        stakeOutcomeAmount:0,
        game_sequence_outcome:[],
        userSelectedGameCategory:[],
        userSelectedGames:[],
       
        apiUrl : process.env.VUE_APP_API_URL,
        isLoading: 0,
        token: localStorage.getItem('auth_token'),
        showAlert:0,
        alertMessage:'',
        toast:useToast(),
      };
    },
    
  mounted()
  {
    console.log(this.$refs);
    this.isLoading =  1;
    this.gameData();

  },
  methods: {
    checkResult()
    {
if(this.gameResult  == 'pending')
{
  this.showAlert = 1;
  this.alertMessage = "Game is still running. Please check back later.";
}
else if(this.gameResult  == 'won' ||  this.gameResult  == 'lost')
{
  this.showResult = true;
}
else{
  this.showAlert = 1;
  this.alertMessage = "Unable to determine game status";
}
    },
    getImageUrl(item) {
    return require(`../../assets/svg/${this.userSelectedGameCategory}/${item}.svg`);
  },
    getUserData(key =  null)
{
  var data = localStorage.getItem('user_data');

if (data) {
  data = JSON.parse(data);
}

if (key && data) {
  return data[key];
}

return data;
},
    openRoute(path) {
      this.$router.push(path);
    },
   
  notifications(data)
      {
        console.log('New notifcation received', data)
        if(data.type == 'info')
        {
          this.showAlert = 1;
          this.alertMessage = data.message;
        }
        else
        {
          this.showToast(data.message);
        }
      },
  
 
    async gameData()
    {
      var gameId = this.playedGameId; 
   
      //post to server
      var payload = 
      {
       
        game_id:  gameId,
     
      }
    
      console.log('Payload');
      console.log(payload);

     


      this.isLoading = 1;
        var endpoint = this.apiUrl  +   '/game/game-details';
        const data = {
    endpoint:endpoint,
    payload:payload
    };
try
{
    var response = await  this.postData(data);
    console.log('Response');
    console.log(response);
    if(response.success)
    {
      var game_data = response.data;
  //    console.log('Selections', game_data.computed_final_selections);
  
      this.userSelectedGameCategory = game_data.app_game_category;
      this.stakeInputAmount = game_data.app_stake_amount;
      this.stakeOutcomeAmount = game_data.app_outcome_amount;
      this.gameCode = game_data.game_code;
      this.playedGameType = game_data.app_outcome_label;
       this.userSelectedGames = JSON.parse(game_data.computed_final_selections);
       this.isLoading = 0;
       this.game_sequence_outcome = game_data.live_game.outcome != null ? JSON.parse(game_data.live_game.outcome) : null;
        this.gameResult = game_data.status ;


    }
   
}
catch(err)
{
    console.log(err);
    this.isLoading = 0;
}

      
    },
    async postData(data) {
      try {
        const response = await axios.post(data.endpoint, data.payload, {
            headers: {
              Authorization: `Bearer ${this.token}`,
              'Content-Type': 'application/json',
            }
        });
        this.isLoading = 0;
        return response.data;
      } catch (error) {


        if(error.response.data && error.response.data.message)
  {
    console.log('Err ',error.response.data.message);
    this.showToast(error.response.data.message);
  }

        console.error('Error sending POST request:', error);
      }
      this.isLoading = 0;
    },
    showToast(message) {
      this.toast.open({
        message: message,
        type: "info",
        duration: 3000, 
        className: "app-toast",
      });
    }
  }
   
  };
  </script>
<style scoped>
.play-game-btn
{
  border-radius: 50px;
    background: #570065;

    height: 62px;
    color: #FFF;
    text-align: center;
    text-shadow: 2px 4px 1px rgba(0, 0, 0, 0.50);
    font-family: Angkor;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.333px;
    margin: 0 auto;
    margin-top: 50px;
    line-height: 60px;
}
.stake-amount-container
{
  border-radius: 30px;
background: #570065;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.75) inset;
width: 222px;
height: 63px;
line-height: 63px;
color: #FFF;

text-align: center;
font-family: Angkor;
font-size: 40px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
margin: 0 auto;
}
.win-amount-container
{
  background: #27D267;
}
.stake-amount-container span
{
  display: inline-block;
  /*margin-top: 7px;*/
}
.played-game-type{
  color: #fff;
    margin-top: 7px;
    font-family: Angkor;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
}
.stake-amount-container input
{
  background: none;
  border: none;
  height: 60%;
max-width: 100%;
  color: #FFF;

text-align: center;
font-family: Angkor;
font-size: 40px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
text-align: left;
    text-indent: 10px;
}
.selected-entry svg
{
  visibility: hidden;
}
.selected-outcome-label
{
  color: #FFF;
    text-align: center;
    font-family: Angkor;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.333px;
    margin-top: 8px;
}
.stake-currency
{
  width: 30%;
  text-align: right;
}
.stake-value
{
  width: 70%;
}
.game-selector-container
{
  max-width: 312px;
    width: 75%;
    height: auto;
    color: #fff;
    text-align: center;
    z-index: 5000;
    border-radius: 30px;
    /*border: 6px solid #FFF;*/
    background: #252A34;
    position: relative;
    margin: 0 auto;
    margin-top: 45px;
    
}
.selected-games-container, .result-outcome-sequence
{
  max-width: 312px;
    width: 75%;
    height: auto;
    color: #fff;
    text-align: center;
    z-index: 5000;
    border-radius: 30px;
    /*border: 6px solid #FFF;*/
   /* background: #252A34;*/
    position: relative;
    margin: 0 auto;
    margin-top: 5px;
    padding-bottom: 33px;
}
.game-entry{
  border-radius: 30px;
    border: 3px solid #FFF;
    background: #000;
    width: 80px;
    height: 85px;
    display: inline-block;
    margin-top: 33px;
}
.selected-preview-entry{
    border-radius: 30px;
    border: 3px solid #FFF;
    background: #000;
    width: 70px;
    height: 70px;
    display: inline-block;
    margin-top: 20px;
}
.selected-preview-entry image
{
  width: 100%;
}
.entry-svg
{
  margin-top: 10px;
}
.entry-separator{
  margin-right: 10px;
    margin-left: 10px;
}
#app-container
{
  position: relative;
    display: inline-block;
    width: 100%;
}
.game-outcome-type
{
  max-width: 312px;
  width: 75%;
  height: auto;
  color: #fff;
  text-align: center;

  z-index: 5000;
  border-radius: 30px;
  border: 6px solid #FFF;
  background: #252A34;
  position: relative;
  margin: 0 auto;
  margin-top: 45px;
}

.active_game_option
{
  filter: blur(5px);
}
.selecting
{
  filter: blur(2px);
}
.game-stage-title, .game-status-title
{
  color:#fff;
  border-radius: 30px;
border: 3px solid #FFF;
background: #000;
display: inline-block;
min-width: 140px;
text-align: center;
height: 27px;
    line-height: 27px;
    margin-top: 10px;
}
.stage-styled-text
{
  color: #F00;
font-family: Chewy;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
}
.game-selector-container
{
  border-radius: 30px;
    background: #252A34;
    width: 317px;
    /* height: 155px; */
    margin: 0 auto;
    margin-top: 50px;
    text-align: center;
    padding-bottom: 33px;
}

.game-options
{
  border-radius: 30px;
border: 3px solid #FFF;
background: #000;
width: 80px;
height: 85px;
display: inline-block;
margin-top: 33px;

}
.posibilities-option
{
  border-radius: 30px;
background: #570065;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.75) inset;
width: 222px;
height: 63px;
margin: 0 auto;
margin-top: 30px;
    margin-bottom: 30px;
    display: inline-block;
}
.posibilities-outcome-title{
  padding-top: 12px;
  color: #FFF;
text-align: center;
font-family: Angkor;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
}
.posibilities-outcome-odds{
  color: #27D267;
text-align: center;
font-family: Angkor;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
}
.game-1
{
  margin-right: 5px;
}
.game-3
{
  margin-right: 11px !important;
    margin-top: 15px !important;
}
.game-options svg
{
  margin-top: 13px;
}
.continue-game-btn
{
  border-radius: 50px;
background: #570065;
max-width: 337px;
height: 62px;
color: #FFF;
text-align: center;
text-shadow: 2px 4px 1px rgba(0, 0, 0, 0.50);
font-family: Angkor;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
margin: 0 auto;
margin-top: 50px;
margin-bottom: 50px;
    line-height: 60px;
}
.start-game-btn
{
  border-radius: 50px;
background: #570065;
max-width: 337px;
height: 62px;
color: #FFF;
text-align: center;
text-shadow: 2px 4px 1px rgba(0, 0, 0, 0.50);
font-family: Angkor;
font-size: 32px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
margin: 0 auto;
margin-top: 50px;
    line-height: 60px;
}
.mlr15
{
  margin-right: 15px;
  margin-left: 15px;
}


.app-logo
{
text-align: center;
margin-top: 70px;
}
.user-profile-holder
{
color: #FFF;
text-align: center;
font-family: Angkor;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
text-align: right;
padding-right: 22%;
}
.dart-target
{
   text-align: center;
   margin-top: 10px;
}
.dart-off-left
{
display: inline-block;
margin-left: -55px;
margin-top: 65px;
position: absolute;
}
.dart-off-right
{
display: inline-block;
margin-left: 195px;
margin-top: -50px;
position: absolute;
}
.happy-play
{
   text-align: right;
}
.happy-play svg
{
   margin-right: -10px;
   margin-top: -65px;
}
.live-game-status
{
margin-top: 0;
}
.live-game-label
{
   color: #FFF;
text-align: center;
font-family: Chewy;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
margin-top: 10px;
}
.live-game-value
{
   color: #F00;
text-align: center;
font-family: Angkor;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
}
.nav-container
{
   margin-top: 20px;
   text-align: center;
}
.nav-option
{
   color: #FFF;
text-align: center;
font-family: Angkor;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
padding: 10px;
width: 97px;
display: block;
border-radius: 50px;
background: #570065;
cursor: pointer;
margin: 0 auto;
margin-bottom: 12px;
}
.nav-settings
{
   text-align: center;
}
.stake-game-label
{
  color: #FFF;
text-align: center;
font-family: Chewy;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
margin-top: 20px;
    margin-bottom: 10px;
}
.stake-value input::placeholder {
            color: #ffffff; 
        }
.result-section-title
{
margin-top: 15px;
font-family: Angkor;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    color:#fff;
    text-align: center;
}
.result-text,.result-icon
{
display: inline-block;
height: 35px;
}
.result-text{
    vertical-align: top;
    padding-top: 8px;
   
}
.selection-bg
{
  background: #047E32;
  padding-top: 10px;
    margin-top: 20px;
}
.user-selection-won
{
  background: #047E32;
  padding-top: 10px;
  margin-top: 20px;
}
.user-selection-lost
{
  background: #FF0000;
  padding-top: 10px;
  margin-top: 20px;
}

</style>