<template>
    <div  class="modal">
<div class="modal-off-icon">
  <svg width="77" height="77">
  <image :xlink:href="requireImage" />
</svg>
</div>
<div class="modal-off-title">{{ modalData[currentIndex].title }}</div>

<div class="nav-container">
        <div class="nav-header">{{ modalData[currentIndex].header }}</div>
        <div class="nav-description">{{ modalData[currentIndex].description }}</div>
        <div class="nav-narration">{{ modalData[currentIndex].narration }}</div>
        <div class="next-btn" v-if="currentIndex < modalData.length - 1" @click="nextItem">
          <svg width="30" height="30">
          <image :xlink:href="nextBtnSrc" />
        </svg>

        </div>
        
</div>

<div class="modal-close" v-if="currentIndex === 0"  @click="closeDynamicModal" >Close</div>
<div class="modal-close" v-if="currentIndex > 0"  @click="previousItem">Back</div>

    </div>
</template>
<script>

export default {
  props: {
    modalData: Array,
  },
  computed: {
    requireImage() {
      return require(`../../assets/svg/${this.modalData[this.currentIndex].image}`);
    },
  },
  data() {
    return {
      currentIndex:0,
      navSettingsSrc: require("../../assets/svg/nav-settings-large.svg"),
      nextBtnSrc: require("../../assets/svg/modal-next.svg"),
 
    };
  },
  
  methods: {

    closeDynamicModal() {
      this.$emit('close-dynamicModal');
    },
    nextItem() {
      this.currentIndex++;
    },
    previousItem() {
      console.log('Prev');
      this.currentIndex--;
    },
  }
  
};
</script>
<style scoped>
.next-btn
{
  text-align: center;
}
.modal
{
    max-width: 312px;
    width: 75%;
    height: auto;
    color: #fff;
    text-align: center;
    line-height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 5000;
    border-radius: 30px;
    border: 6px solid #FFF;
    background: rgba(255, 255, 255, 0.50);
}
.nav-container
{
   margin-top: 20px;
   text-align: center;
   min-height: 220px;
}
.nav-header
{
    color: #FFF;
    text-align: center;
    font-family: Angkor;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: -0.333px;
    padding: 10px;
    max-width: 222px;
    display: block;
    cursor: pointer;
    margin: 0 auto;
    margin-bottom: 12px;

    border-radius: 30px;
background: #570065;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.75) inset;
}
.nav-description
{
  color: #F00;
text-align: center;
font-family: Angkor;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
margin-top: 16px;
    margin-bottom: 10px;
}
.nav-narration{
  color: #FFF;
text-align: center;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 30px;
letter-spacing: -0.333px;
}
.modal-off-icon{
    display: inline-block;
    position: absolute;
    left: 0;
    margin-left: -32px;
    margin-top: -42px;
}
.modal-off-title
{
    display: inline-block;
    position: absolute;
    margin-top: -70px;
    right: 0;
    margin-left: 53px;
    font-family: Angkor;
font-size: 13px;
font-style: normal;
font-weight: 400;
}
.modal-close
{
    display: inline-block;
    position: absolute;
    color: #F00;
text-align: center;
font-family: Angkor;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
right: 0;
    margin-right: 12px;
    margin-top: 12px;
}

</style>