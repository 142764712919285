<template>

      <div class="mobile-alert">
        <div class="alert-content">
          <p>{{ alertMessage }}</p>
          <button @click="closeAlert">Close</button>
        </div>
      </div>

  </template>
  
  <script>

  export default {
    props: {
        alertMessage: '',
  },
    data() {
      return {
        show: false,
        message: "Testing now now now",
      };
    },
    methods: {
      closeAlert() {
        this.$emit('close-alert');
      },
    },
  };
  </script>
  
  <style scoped>
  .mobile-alert {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    z-index: 20000;
  }
  
  .alert-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 90%;
    animation: slide-up 0.3s ease-out;
  }
  
  button {
    margin-top: 10px;
    background: #570065;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  @keyframes slide-up {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s;
  }
  
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  </style>
  