<template>
  <div id="app">
  <router-view></router-view>
  <div class="app-toast">
    <div class="toast-content">
      {{ message }}
    </div>
  </div>
</div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import Start from '@/components/Dashboard/Start.vue'
import Settings from '@/components/Modals/Settings.vue'
import GeneralModal from '@/components/Modals/GeneralModal.vue'    
import Loading from '@/components/Modals/Loading.vue'
import axios from 'axios';

import {useToast} from 'vue-toast-notification';

export default {
  name: 'App',
  components: {Start, Settings, GeneralModal},

  data()
  {
    return {
      apiUrl : process.env.VUE_APP_API_URL,
      userName: "John",
      showSettings : 0,
      backdrop : 0,
      openGeneralModal:0,
      modalData:[],
      liveGameTime:'00:00',
      isAuthenticated: false,
      minutes: 0,
      seconds: 0,
      isLoading:0,
      token: localStorage.getItem('auth_token'),
      lastGameRequestFailed: false,
      toast:useToast(),
    }
  },

  methods: {

    async postData(data) {
      try {
        const response = await axios.post(data.endpoint, data.payload, {
            headers: {
              Authorization: `Bearer ${this.token}`,
              'Content-Type': 'application/json',
            }
        });
        this.isLoading = 0;
        const statusCode = response.status;
     
        return response.data;
      } catch (error) {
        if(error.response &&  error.response.status ==  401)
        {
          console.log('Not authenticated');
          this.showToast('Please sign in');
          localStorage.removeItem('auth_token');
          this.$router.push('/');
        }
  else if(error.response.data && error.response.data.message)
  {
    console.log('Err ',error.response.data.message);
    this.showToast(error.response.data.message);
  }

return error;
      //  console.error('Error sending POST request:', error);
      }
      this.isLoading = 0;
    },

    async getServerTime() {

     // this.serverTime = new Date().getTime(); 
    //  this.startCountdown();


      var endpoint = this.apiUrl  +   '/dashboard/live-data';
        const data = {
    endpoint:endpoint,
    payload:{
   
    }
    };
try
{
  if(this.token &&  this.lastGameRequestFailed == false)
  {
  
    //if error and message is no game, wait 5 minutes before sending another server request
    var response = await  this.postData(data);
    if(response && response.timer)
    {
      this.lastFailedRequestTime = 0;
     localStorage.setItem('live_data', JSON.stringify(response));
      console.log('Server time: ', response.timer);
      this.lastGameRequestFailed = false;
    return response.timer
    }
    else 
    {
      localStorage.setItem('live_data', null);
      this.lastGameRequestFailed = false;

console.log(response);
      if(response.response.data && response.response.data.message ==  "No live game at the moment")
  {
    this.lastGameRequestFailed = true;
    localStorage.removeItem('live_data');
  setTimeout(function() {
    this.lastGameRequestFailed = false;
  }, 300000); 
  }
      return '00:00';
    }
   
  }
  return '00:00';
}
catch(err)
{
  localStorage.removeItem('live_data');
  console.log(err);
  return '00:00';

}

    },
    async startCountdown() {
      console.log('Count down');
      const serverTime = await this.getServerTime();
      console.log('Server time', serverTime);
      const [serverMinutes, serverSeconds] = serverTime.split(':').map(Number);

      // Calculate the initial time remaining
      let remainingMinutes = serverMinutes;
      let remainingSeconds = serverSeconds;

      // Update the countdown
      this.minutes = String(remainingMinutes).padStart(2, '0');
      this.seconds = String(remainingSeconds).padStart(2, '0');

      // Create an interval to update the countdown every second
      this.countdownInterval = setInterval(() => {
        if (remainingSeconds === 0) {
          remainingMinutes -= 1;
          remainingSeconds = 59;
        } else {
          remainingSeconds -= 1;
        }

        if (remainingMinutes < 0) {
          // Reset the countdown when it reaches 00:00
          this.resetCountdown();
        } else {
          this.minutes = String(remainingMinutes).padStart(2, '0');
          this.seconds = String(remainingSeconds).padStart(2, '0');
        }
//        console.log(this.minutes + ':'+this.seconds);
        $('.live-game-value').html(this.minutes + ':'+this.seconds);
      }, 1000);
    },

    // Function to reset the countdown
    resetCountdown() {
      clearInterval(this.countdownInterval);
      this.startCountdown();
    },
    showToast(message) {
      this.toast.open({
        message: message,
        type: "info",
        duration: 3000, 
        className: "app-toast",
      });
    }
  },
  mounted() {
    this.startCountdown();
  },
  beforeDestroy() {
    clearInterval(this.countdownInterval);
  },
  /*
  components: {
    HelloWorld
  }
  */
}
</script>
<style >
@font-face {
  font-family: 'Angkor'; 
  src: url('@/assets/fonts/Angkor-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
*, ::before, ::after {
    box-sizing: content-box!important;
}
body,html
{
   background: #000;
}
body
{
  max-width: 400px !important;
  margin: 0 auto;
  
}
#app
{
  max-width: 100%;
    overflow: hidden;
}
.backdrop
{
  position: absolute;
    height: 100vh;
    width: 100%;
    top: 0;
    filter: blur(5px);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
input:focus {
  outline: none;
}
.splash-enter-active, .splash-leave-active {
  transition: all 0.3s ease;
}

.splash-enter-from {
  transform: scale(0);
  opacity: 0;
}

.splash-enter-to, .splash-leave-to {
  transform: scale(1);
  opacity: 1;
}


.app-toast {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px;

  
}
.v-toast__text
{

  background: #580065c2;
  color: white;
  border-radius: 5px;
  padding: 10px 20px;
}


.toast-content {
  font-size: 16px;
}
</style>


