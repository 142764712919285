<template>
    <div id="app-container" class="wrapper">
      <div class="app-logo">
        <img :src="logoSrc" />
      </div>
  
      <div class="user-profile-holder">
        <span>Hi </span>
        <span class="user-profile-name">{{ userName }}</span>
      </div>
  
      <div class="dart-target">
       
        <div class="global-home" @click="openRoute('/Start')">
          <svg width="77" height="77">
            <image :xlink:href="globalHomeSrc" />
          </svg>
        </div>

        <div class="live-game-status">
        <div class="live-game-label">Live Game</div>
        <div class="live-game-value">00:00</div>
      </div>

      <div class="game-stage-title">
          Game <span class="stage-styled-text">Submitted</span>
      </div>

       

         <!-- 
    <div v-if="showPreview" class="selected-outcome-label">{{ selectedOutcomeLabel }}</div>
    -->

       
  
      </div>
  

<section ref="thumbs-up-group">

<div class="thumbs-up-container">
  <svg width="300" height="300">
            <image :xlink:href="thumbsUp" />
          </svg>
        
</div>


<div class="play-again-btn"  @click="openRoute('/Play')" >
 Play Again!
</div>


</section>

    </div>
 
 

  </template>
  <script>
   
  //import GameOutcomeTypeModal from '../../components/Modals/GameOutcomeTypeModal.vue'
  export default {
  //  components: {GameOutcomeTypeModal},
    data() {
      return {
       
        logoSrc: require("../../assets/images/logo.png"),
        globalHomeSrc: require("../../assets/svg/global-home.svg"),
        thumbsUp: require("../../assets/svg/thumbs-up.svg"),
        userName: this.getUserData('name').split(' ')[0],
     
      };
    },
    
  
  mounted()
  {
    console.log(this.$refs);
  },
  methods: {
    getUserData(key =  null)
{
  var data = localStorage.getItem('user_data');

if (data) {
  data = JSON.parse(data);
}

if (key && data) {
  return data[key];
}

return data;
},
    openRoute(path) {
      this.$router.push(path);
    },


  

  }
   
  };
  </script>
<style scoped>
.thumbs-up-container
{
  max-width: 300px;
  height: 300px;
  margin: 0 auto;
  margin-top: 20px;
}
.play-again-btn
{
  border-radius: 50px;
    background: #570065;
    max-width: 312px;
    height: 62px;
    color: #FFF;
    text-align: center;
    text-shadow: 2px 4px 1px rgba(0, 0, 0, 0.50);
    font-family: Angkor;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.333px;
    margin: 0 auto;
    margin-top: 20px;
    line-height: 60px;
}
.play-again-btn a
{
  color: #FFF;
  text-decoration: none;
}





.entry-svg
{
  margin-top: 10px;
}
.entry-separator{
  margin-right: 10px;
    margin-left: 10px;
}
#app-container
{
  position: relative;
    display: inline-block;
    width: 100%;
}



.game-stage-title
{
  color:#fff;
  border-radius: 30px;
border: 3px solid #FFF;
background: #000;
display: inline-block;
min-width: 140px;
text-align: center;
height: 27px;
    line-height: 27px;
    margin-top: 10px;
}
.stage-styled-text
{
  color: #27D267;
font-family: Chewy;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
}


.app-logo
{
text-align: center;
margin-top: 70px;
}
.user-profile-holder
{
color: #FFF;
text-align: center;
font-family: Angkor;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
text-align: right;
padding-right: 22%;
}
.dart-target
{
   text-align: center;
   margin-top: 10px;
}


.live-game-status
{
margin-top: 0;
}
.live-game-label
{
   color: #FFF;
text-align: center;
font-family: Chewy;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
margin-top: 10px;
}
.live-game-value
{
   color: #F00;
text-align: center;
font-family: Angkor;
font-size: 24px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: -0.333px;
}

</style>