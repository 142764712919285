let isAuthenticated = false;

export function checkIfUserIsAuthenticated() {
  var authToken = localStorage.getItem('auth_token');
  return authToken  == null ? false : true;
}

export function login() {
  isAuthenticated = true;
}

export function logout() {
  isAuthenticated = false;
}